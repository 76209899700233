import React, { useEffect, useRef, useState } from 'react'
import { ScissorOutlined } from '@ant-design/icons'
import { Slider, Switch, Button, Popover } from 'antd'
import type { SliderSingleProps } from 'antd/es/slider'
import { getVideoPreview } from 'polotno/utils/video'
import './videoTrimmer.less'

const VideoTrimmer = ({ videoUrl, store, pageId, setPageDuration }: any) => {
  const [preview, setPreview] = useState<string>('')
  const [videoLength, setVideoLength] = useState<number | null>(null)
  const [disabled, setDisabled] = useState(false)
  const [savedStartTime, setSavedStartTime] = useState(0)
  const [savedEndTime, setSavedEndTime] = useState(0)
  const [sliderValues, setSliderValues] = useState<number[]>([0, 0])
  const videoEl = useRef(null)
  const children = store.pages.filter((page: any) => page.id === pageId)[0]?.children

  const videoElements = children?.filter((child: any) => child.type === 'video')
  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => (
    <ImageTooltip value={value as number} />
  )

  // Custom tooltip component to show the image
  const ImageTooltip = ({ value }: { value: number }) => (
    <div>
      <img src={preview} alt='Tooltip' style={{ width: '50px', height: '50px' }} />
      <p>{value} sec</p>
    </div>
  )

  function normalize(value: number, min: number, max: number) {
    const clampedValue = Math.max(min, Math.min(value, max))
    return (clampedValue - min) / (max - min)
  }

  function denormalize(normalizedValue: any, min: number, max: number) {
    return normalizedValue * (max - min) + min
  }

  function secondsToMilliseconds(seconds: number) {
    return seconds * 1000
  }

  const onChange = async (value: number[]) => {
    setSliderValues(value)
    const [startTime, endTime] = value
    const pageDurationInsec = endTime - startTime
    const durationAfterTrimming = secondsToMilliseconds(pageDurationInsec)
    setPageDuration(durationAfterTrimming)

    if (!videoLength || !store) return

    const normalisedStartTime = normalize(startTime, 0, videoLength)
    const normalisedEndTime = normalize(endTime, 0, videoLength)

    const largestVideo = store.pages
      .find((page: any) => page.id === pageId)
      ?.children.filter((child: any) => child.type === 'video')
      ?.reduce(
        (prev: any, current: any) => (prev.width * prev.height > current.width * current.height ? prev : current),
        {}
      )

    if (largestVideo) {
      const element = store.getElementById(largestVideo.id)
      element.set({
        startTime: normalisedStartTime,
        endTime: normalisedEndTime,
      })
      store.getElementById(pageId).set({ duration: durationAfterTrimming })
      const videoPreview = await getVideoPreview(
        videoUrl || 'https://media.gettyimages.com/video/sample.mp4',
        normalisedEndTime
      )
      setPreview(videoPreview)
    }
  }

  useEffect(() => {
    if (videoElements?.length > 0) {
      const largestVideo = videoElements.reduce(
        (prev: any, current: any) => (prev.width * prev.height > current.width * current.height ? prev : current),
        videoElements[0]
      )
      const element = store.getElementById(largestVideo.id)
      setSavedStartTime(denormalize(element.startTime, 0, videoLength || 0))
      setSavedEndTime(denormalize(element.endTime, 0, videoLength || 0))
    }
  }, [videoLength, pageId])

  useEffect(() => {
    // Set the slider values once savedStartTime and savedEndTime are updated
    if (savedStartTime !== null && savedEndTime !== null) {
      setSliderValues([savedStartTime, savedEndTime])
    }
  }, [savedStartTime, savedEndTime])

  const handleLoadedMetadata = async () => {
    const video: any = videoEl.current
    if (!video) return
    if (video?.duration) {
      setVideoLength(video.duration)
    }
    const videoPreview = await getVideoPreview(videoUrl || 'https://media.gettyimages.com/video/sample.mp4')
    setPreview(videoPreview)
  }

  return (
    <>
      <Slider
        className='custom-slider'
        step={0.1}
        min={0}
        max={videoLength || 0}
        range
        value={sliderValues}
        disabled={disabled}
        onChange={onChange}
        tooltip={{ formatter }}
      />
      <video src={videoUrl} ref={videoEl} onLoadedMetadata={handleLoadedMetadata} style={{ display: 'none' }} />
    </>
  )
}

export default VideoTrimmer
