import { Button, Modal, Row, Col, Select, Input, Flex, message, Popconfirm, Slider, SliderSingleProps } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { SlidersOutlined, PlayCircleOutlined, HeartOutlined, HeartFilled, FilterOutlined } from '@ant-design/icons'
import { languages, voiceFilter } from '@src/shared/utils/aiVoiceFilter'
import { formatDuration } from '@src/shared/utils/convertSecs'
import {
  deleteFavouredVoice,
  getFavouredVoices,
  saveFavoriteVoices,
} from '@src/shared/utils/VideoGeneration/helperFunctions/voiceFavorite'
import { AnyMxRecord } from 'dns'
const { Option } = Select
const { Search } = Input
interface FavouredVoiceData {
  user_id: string
  voice_name: string | null
  createdAt: string
  id: string
  voice_id: string | null
  updatedAt: string | null
}
export const VoiceFilterModal = ({ aiVoice, handleChangeVoice, selectedVoiceName }: any) => {
  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value: any) => `${value}%`

  const filterVariables = voiceFilter(aiVoice)
  const [voicesOpen, setVoicesOpen] = useState(false)
  const [selectedAccent, setSelectedAccent] = useState<string | undefined>(undefined)
  const [playingUrl, setPlayingUrl] = useState('')
  const [selectedAge, setSelectedAge] = useState<string | undefined>(undefined)
  const [selectedGender, setSelectedGender] = useState<string | undefined>(undefined)
  const [selectedUseCase, setSelectedUseCase] = useState<string | undefined>(undefined)
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(undefined)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [favouredVoices, setFavouredVoices] = useState<FavouredVoiceData[]>([])

  const audioReff = useRef<HTMLAudioElement | null>(null)

  const [Stability, setstability] = useState(50)
  const [Similarity, setsimilarity] = useState(75)

  const handleCancel = () => {
    setVoicesOpen(false)
  }

  useEffect(() => {
    async function favouredVoices() {
      try {
        const favouredVoices = await getFavouredVoices()

        setFavouredVoices(favouredVoices)
      } catch {
        console.error('Failed to get favorite voices.')
      }
    }
    favouredVoices()
  }, [])

  const handleFavoriteDelete = async (id: string) => {
    try {
      // Assuming you have a backend API call to delete by voice_id
      await deleteFavouredVoice(id)
      setFavouredVoices((prevData) => prevData.filter((voice) => voice.id !== id))
    } catch (error) {
      message.warning('There was a problem deleting the cloned voice.')
    }
  }
  const handleFilterChange = (value: string, filterType: string) => {
    switch (filterType) {
      case 'Accent':
        setSelectedAccent(value)
        break
      case 'Age':
        setSelectedAge(value)
        break
      case 'Gender':
        setSelectedGender(value)
        break
      case 'UseCase':
        setSelectedUseCase(value)
        break
      case 'Language':
        setSelectedLanguage(value)
        break
      default:
        break
    }
  }
  const filteredVoices = aiVoice
    .filter((voice: any) => voice?.category !== 'cloned')
    .filter((voice: any) => {
      const voiceLabels = voice.labels || {}
      const matchesAccent = selectedAccent ? voiceLabels.accent === selectedAccent || selectedAccent === 'All' : true
      const matchesAge = selectedAge ? voiceLabels.age === selectedAge || selectedAge === 'All' : true
      const matchesGender = selectedGender ? voiceLabels.gender === selectedGender || selectedGender === 'All' : true
      const matchesUseCase = selectedUseCase
        ? voiceLabels.use_case === selectedUseCase || selectedUseCase === 'All'
        : true
      const matchesSearchQuery = searchQuery ? voice?.name.toLowerCase().includes(searchQuery.toLowerCase()) : true
      const matchesLanguages = selectedLanguage
        ? voice.fine_tuning?.language === selectedLanguage || selectedLanguage === 'All'
        : true
      return matchesAccent && matchesAge && matchesGender && matchesUseCase && matchesSearchQuery && matchesLanguages
    })
  const modalTitle = (
    <div style={{ display: 'flex', flexDirection: 'row', background: 'white' }}>
      <div
        style={{ width: '100%' }}
        className='draggable-modal-title' // This class will be used as a handle
      >
        AI Voices
      </div>
    </div>
  )

  const handleToggleFavorite = async (e: any, voice_id: string, voice_name: string, id: string) => {
    e.stopPropagation()

    const isFavorite = favouredVoices.some((favVoice) => favVoice.voice_id === voice_id)
    const favouredVoice = favouredVoices?.find((favVoice: any) => favVoice.voice_id === voice_id)
    const favoriteId = favouredVoice ? favouredVoice.id : ''

    if (isFavorite) {
      handleFavoriteDelete(favoriteId)
    } else {
      try {
        await saveFavoriteVoices(voice_name, voice_id)
        // setFavouredVoices((prevData:any) => [
        //   ...prevData,
        //   { voice_id:voice_id, voice_name:voice_name, id: favoriteId  } // Ensure you add an id for new entries
        // ]);
        const favouredVoices = await getFavouredVoices()

        setFavouredVoices(favouredVoices)
      } catch {
        message.error('Failed to mark favorite.')
      }
    }
  }
  const handlePlayAudioTwo = (previewUrl: string) => {
    if (audioReff.current) {
      audioReff.current.src = previewUrl

      audioReff.current.addEventListener('loadedmetadata', () => {
        // Log duration
      })
      audioReff.current.play().catch((e: any) => console.error('Playback was interrupted:', e))
      setPlayingUrl(previewUrl)
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          setVoicesOpen(true)
          //   showModal()
          //   store.openSidePanel('video-timeline')
        }}
        style={{
          background: 'transparent',
          borderColor: 'var(--color-primary-500)',
          color: 'var(--color-primary-500)',
        }}
      >
        <FilterOutlined />
        {/* {'Filter'} */}
      </Button>
      <Modal
        title={modalTitle}
        width={1200}
        open={voicesOpen}
        cancelText={'Close'}
        onCancel={handleCancel}
        styles={{ body: { maxHeight: '70vh', overflowY: 'auto' } }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
      >
        <>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Search
                placeholder='Search by Name'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: '16px' }}
                allowClear
              />
            </Col>

            <Col span={4}>
              <Select
                placeholder='Select Language'
                onChange={(value) => handleFilterChange(value, 'Language')}
                // onChange={(value) => handleFilterChange(value, 'Accent')}
                style={{ width: '100%' }}
              >
                <Option key={'default'} value={'All'}>
                  {'All'}
                </Option>
                {languages.map((langauge: any, index: any) => (
                  <Option key={index} value={langauge.value}>
                    {langauge.label}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <Select
                placeholder='Select Accent'
                onChange={(value) => handleFilterChange(value, 'Accent')}
                // onChange={(value) => handleFilterChange(value, 'Accent')}
                style={{ width: '100%' }}
              >
                <Option key={'default'} value={'All'}>
                  {'All'}
                </Option>
                {filterVariables?.accents
                  .filter((accent: any): accent is string => accent !== undefined)
                  .map((accent: any) => (
                    <Option key={accent} value={accent}>
                      {accent}
                    </Option>
                  ))}
              </Select>
            </Col>

            <Col span={4}>
              <Select
                placeholder='Select Age'
                onChange={(value) => handleFilterChange(value, 'Age')}
                style={{ width: '100%' }}
              >
                <Option key={'default'} value={'All'}>
                  {'All'}
                </Option>
                {filterVariables?.ages
                  .filter((age: any): age is string => age !== undefined)
                  .map((age: any) => (
                    <Option key={age} value={age}>
                      {age}
                    </Option>
                  ))}
                {/* Add more age options */}
              </Select>
            </Col>

            <Col span={4}>
              <Select
                placeholder='Select Gender'
                onChange={(value) => handleFilterChange(value, 'Gender')}
                style={{ width: '100%' }}
              >
                <Option key={'default'} value={'All'}>
                  {'All'}
                </Option>
                {filterVariables?.genders
                  .filter((gender: any): gender is string => gender !== undefined)
                  .map((gender: any) => (
                    <Option key={gender} value={gender}>
                      {gender}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={4}>
              <Select
                placeholder='Select Use case'
                onChange={(value) => handleFilterChange(value, 'UseCase')}
                style={{ width: '100%' }}
              >
                <Option key={'default'} value={'All'}>
                  {'All'}
                </Option>
                {filterVariables?.usecases
                  .filter((usecase: any): usecase is string => usecase !== undefined)
                  .map((usecase: any) => (
                    <Option key={usecase} value={usecase}>
                      {usecase}
                    </Option>
                  ))}
              </Select>
            </Col>

            {/* <Col span={6}>
              <Select
                placeholder='Select Language'
                // onChange={(value) => handleFilterChange(value, 'Language')}
                style={{ width: '100%' }}
              >
                {filterVariables?.languages
                  .filter(
                    (language: any): language is string =>
                      language !== undefined
                  )
                  .map((language: any) => (
                    <Option key={language} value={language}>
                      {language}
                    </Option>
                  ))}
              </Select>
            </Col> */}
          </Row>
          <Row gutter={[16, 16]} style={{ marginLeft: '15px' }}>
            <Col span={8}>
              <p
                style={{
                  color: 'black',
                  fontSize: '13px',
                  fontWeight: 500,
                  fontFamily: 'DM Sans',
                  letterSpacing: 'normal',
                  marginTop: '10px',
                }}
              >
                Stability
              </p>
              <Slider
                style={{
                  width: '80%',
                }}
                min={0}
                max={100}
                onChange={(value) => setstability(value)}
                value={Stability}
                tooltip={{ formatter }}
                marks={{
                  0: 'Variable',
                  100: 'Stable',
                }}
              />
            </Col>
            <Col span={8}>
              <p
                style={{
                  color: 'black',
                  fontSize: '13px',
                  fontWeight: 500,
                  fontFamily: 'DM Sans',
                  letterSpacing: 'normal',
                  marginTop: '10px',
                }}
              >
                Similarity
              </p>
              <Slider
                style={{
                  width: '80%',
                }}
                min={0}
                max={100}
                value={Similarity}
                onChange={(value) => setsimilarity(value)}
                tooltip={{ formatter }}
                marks={{
                  0: 'Low',
                  100: 'High',
                }}
              />
            </Col>
            {/* <Col span={3} style={{alignContent:'center'}}>
              <Button
                onClick={() => {
                  applySettings()
                }}
                style={{
                  background: "transparent",
                  borderColor: "var(--color-primary-500)",
                  color: "var(--color-primary-500)",
                }}
              >
                Apply Settings
              </Button>
            </Col> */}
          </Row>
          <Row gutter={[16, 16]}>
            {filteredVoices
              .filter((voice: any) => voice?.category !== 'cloned')
              .map((voice: any, index: number) => (
                <Popconfirm
                  title='Sure to change?'
                  onConfirm={() => handleChangeVoice(voice.voice_id, Similarity, Stability)}
                  key={index}
                >
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    key={index}
                    // onClick={() => {
                    //   console.log('---');
                    //   handleChangeVoice(voice.voice_id);
                    // }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div
                      style={{
                        padding: '16px',
                        border:
                          selectedVoiceName === voice.name ? '4px solid var(--color-primary-500)' : '1px solid #ddd',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h3>{voice?.name}</h3>
                        <Button
                          type='text'
                          icon={
                            favouredVoices?.some((favVoice) => favVoice.voice_id === voice.voice_id) ? (
                              <HeartFilled style={{ color: 'red' }} />
                            ) : (
                              <HeartOutlined />
                            )
                          }
                          onClick={(e) => handleToggleFavorite(e, voice.voice_id, voice.name, voice.id)}
                        />
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '5px',
                          fontWeight: '700',
                        }}
                      >
                        <div
                          style={{
                            textTransform: 'capitalize',
                            color: 'var(--color-primary-500)',
                          }}
                        >
                          {voice.labels?.accent}
                        </div>
                        <div
                          style={{
                            textTransform: 'capitalize',
                            color: 'var(--color-primary-500)',
                          }}
                        >
                          {voice?.labels?.age}
                        </div>
                        <div
                          style={{
                            textTransform: 'capitalize',
                            color: 'var(--color-primary-500)',
                          }}
                        >
                          {voice?.labels?.gender}
                        </div>
                        <div
                          style={{
                            textTransform: 'capitalize',
                            color: 'var(--color-primary-500)',
                          }}
                        >
                          {voice?.fine_tuning?.language}
                        </div>{' '}
                        {/* Corrected spelling */}
                        <div
                          style={{
                            textTransform: 'capitalize',
                            color: 'var(--color-primary-500)',
                          }}
                        >
                          {voice?.labels?.use_case}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'var(--color-primary-500)',
                        }}
                      >
                        <Button
                          type='text'
                          icon={<PlayCircleOutlined />}
                          onClick={(e) => {
                            e.stopPropagation()

                            setPlayingUrl(voice.preview_url)
                            handlePlayAudioTwo(voice.preview_url)
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Popconfirm>
              ))}
          </Row>
        </>
      </Modal>
      <audio ref={audioReff} style={{ display: 'none' }} />
    </>
  )
}
