/* eslint-disable react/prop-types */
import { burstImageTransitionFunctions } from '../../../../src/data/transitions'
import { VideoPlayer } from '../../../../src/features/video/player/videoPlayer'
import { millisecondsToFrames, prefetchAsset } from '../../../../src/shared/utils/remotion'
import { Alert, Button, Row, Col, message, Flex, Typography } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../../../context/globalContext'
import './customPolotno/customActionControlContainer.less'
import {
  extractPagesFromHtml,
  addIdsToHtmlFromJson,
  removeVideoTags,
} from '../../../../src/shared/utils/remotion/htmlProcessing'

import SectionStoryBoard from './storyBoard/SectionStoryBoard'

import { getElementIdJsonPageTavusPayload, getNonAvatarScriptIndex } from '@src/shared/utils/findReplicaJson'
import { avatarRendering } from '@src/shared/utils/VideoGeneration/helperFunctions/aiAvatarAsync'
import { getCookie, setCookie } from 'cookies-next'
import { observer } from 'mobx-react-lite'
import { triggerDownload } from '@src/shared/utils/VideoGeneration/AssetDownload'
const { Title } = Typography

// a previw modal which plays the video
interface VideoPreviewProps {
  store: any // Ideally, replace `any` with a more specific type
}

const PreviewButtonSection: React.FC<VideoPreviewProps> = ({ store }) => {
  // const taskId = getCookie("renderTask_id");

  const {
    TransitionAnimation: [transitionAnimation],
    ScriptDetails: [scriptDetails],
    VideoStyle: [videoStyle],
    ShowTextOverlay: [showTextOverlay],

    UserDetail: [userDetails],
    ShowAdvancedEditor: [, setShowAdvancedEditor],
    VideoRenderStatus: [videoRenderStatus, setVideoRenderStatus],
    StoreUpdatedInBasic: [storeUpdatedInBasic, setStoreUpdatedInBasic],
  } = useGlobalContext()
  const projectID = location.pathname.split('/').pop()
  // loading for when gif is rendering
  const [isGifRendering, setIsGifRendering] = useState<boolean>(false)
  const [IsVideoRender, setIsVideoRendering] = useState(false)

  // a function to render the video

  const handleRender = async () => {
    // message.warning('We are experiencing an issue while retrieving HD assets. We are fixing it; please try downloading again after some time.')
    // return
    setIsVideoRendering(true)

    const avatarRenderResponse = await avatarRendering(
      store,
      scriptDetails,
      userDetails,
      videoStyle,
      transitionAnimation,
      projectID
    )

    if (avatarRenderResponse) {
      setVideoRenderStatus({
        task_id: avatarRenderResponse.task_id,
        video_url: '',
        status: '',
        progressPer: 0,
      })
      message.info(
        'Video rendering has started. We will notify you with the download link via email once it is completed.'
      )
      setIsVideoRendering(false)
      // setShowAdvancedEditor(true);
      return
    } else {
      message.info('Failed to render')

      setIsVideoRendering(false)
      return
    }
  }
  // closes the preview modal
  const handleOk = () => {
    // shows the captions on polotno for user to see where the captions will be placed in the video
    store.history.ignore(async () => {
      store.pages.map((page: any) => {
        if (page?.custom?.showSubtitles === true) {
          const elementID = 'captionText' + page.id
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 1,
            })
          }
        }
      })
    })
    setShowAdvancedEditor(true)
  }

  const [width, setWidth] = useState<number>(500)
  const [height, setHeight] = useState<number>(600)
  const [duration, setDuration] = useState<number>(1)
  const [htmlCode, setHtmlCode] = useState([])
  const [burstImageAnimations, setBurstImageAnimations] = useState<any>([])

  // below are functions to set the new values
  const changeHtmlCode = (newHtmlCode: any) => {
    setHtmlCode(newHtmlCode)
  }

  const changeDuration = (newDuration: number) => {
    setDuration(newDuration)
  }

  const changeWidth = (newWidth: any) => {
    let adjustedWidth = 704
    if (newWidth == 1280) {
      adjustedWidth = 704
    } else if (newWidth == 720) {
      adjustedWidth = 400
    } else if (newWidth == 800) {
      adjustedWidth = 536
    } else {
      adjustedWidth = 704
    }

    setWidth(adjustedWidth)

    // const modalBody = document.querySelector('.ant-modal-body');
    // if (modalBody && modalBody.firstChild) {
    //   const firstChild = modalBody.firstChild as HTMLElement; // Casting to HTMLElement
    //   firstChild.style.width = `${adjustedWidth}px`;
    //   // firstChild.style.height = `${dynamicHeight}px`;
    //   // firstChild.style.backgroundColor = 'lightblue'; // Optional for visibility
    // }
  }

  const changeHeight = (newHeight: any) => {
    setHeight(newHeight)
  }

  // preview modal title
  const modalTitle = (
    <>
      <div
        style={{ width: '100%', cursor: 'move' }}
        className='draggable-modal-title' // This class will be used as a handle
      >
        Video Preview
      </div>
      <Alert
        message='Any watermark on the video will be removed in the download.'
        type='info'
        style={{
          fontSize: '12px',
        }}
      />
    </>
  )

  // a use effect to load all the props that are required for previewing a video
  useEffect(() => {
    // console.log(previewOpen)
    async function prefetchAndRender() {
      setIsGifRendering(true)

      // getting the json data from polotno
      const jsonData = store.toJSON()

      // if the video style is burst we make an array of random transition functions
      const burstImagesTransitionAnimationArray = []

      if (videoStyle === 'Burst') {
        for (let i = 0; i < scriptDetails.data.length; i++) {
          const innerArray = []

          for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
            const randomFunction =
              burstImageTransitionFunctions[Math.floor(Math.random() * burstImageTransitionFunctions.length)]
            // innerArray.push(randomFunction.value)
            innerArray.push(transitionAnimation[0].animation)
          }
          burstImagesTransitionAnimationArray.push(innerArray)

          await prefetchAsset(scriptDetails?.data[i].sounds[0].url)
        }

        setBurstImageAnimations(burstImagesTransitionAnimationArray)
      }

      prefetchAllAssets(jsonData) // prefetching all the assets

      // prefetching the background music so it plays without lag
      await prefetchAsset(scriptDetails?.backgroundMusic?.url)
      await prefetchAsset(
        scriptDetails?.customTransitionSound ||
          'https://d2tqfxp1334laf.cloudfront.net/image_projects/da764da2-2982-49be-88bd-eca414c4f5d7/assets/audio/e4cdb276-d1ad-4d81-91ae-3182d55c4099/audio.mp3'
      )
      // converting the duration thats in store which is in miliseconds into duration that remotion can understand. Fps is 30, can be changed in the furture
      changeDuration(millisecondsToFrames(store.duration, 30))

      /* this is done so that the caption text on polotno does not show up in the preview video.
    the text shown on polotno when captions is enabled is only shown visually for the user*/
      store.pages.map((page: any) => {
        const elementID = 'captionText' + page.id
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
          })
        }
      })

      await store.pages.map((page: any) => {
        page.children.map((child: any) => {
          if (child.name === 'h1-notlinked') {
            const elementID = child.id
            if (store.getElementById(elementID)) {
              store.getElementById(elementID).set({
                visible: showTextOverlay,
                opacity: showTextOverlay ? 1 : 0,
                // custom.custom:
              })
            }
          }
        })
      })
      // console.log(store.toJSON())

      // getting the html code
      const result = await store.toHTML()
      const mutedHtml = result.replace(/<video/g, '<video muted')
      // console.log(result)
      // converting the html code into different pages so it reflects each page in json
      const htmlCode = extractPagesFromHtml(removeVideoTags(mutedHtml))

      // adding tags into that htmlCode array so that we can show animations
      const htmlCodeWithTags = addIdsToHtmlFromJson(htmlCode, jsonData)
      await prefetchAsset(scriptDetails?.customTransitionSoundurl)

      changeHtmlCode(htmlCodeWithTags)
      changeWidth(store.width)
      changeHeight(store.height)
      setIsGifRendering(false)
      setStoreUpdatedInBasic(false)
    }

    prefetchAndRender()
  }, [])

  //reoload if store changes
  useEffect(() => {
    async function prefetchAndRender() {
      setIsGifRendering(true)

      // getting the json data from polotno
      const jsonData = store.toJSON()

      // if the video style is burst we make an array of random transition functions
      const burstImagesTransitionAnimationArray = []

      if (videoStyle === 'Burst') {
        for (let i = 0; i < scriptDetails.data.length; i++) {
          const innerArray = []

          for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
            const randomFunction =
              burstImageTransitionFunctions[Math.floor(Math.random() * burstImageTransitionFunctions.length)]
            // innerArray.push(randomFunction.value)
            innerArray.push(transitionAnimation[0].animation)
          }
          burstImagesTransitionAnimationArray.push(innerArray)
          await prefetchAsset(scriptDetails?.data[i].sounds[0].url)
        }

        setBurstImageAnimations(burstImagesTransitionAnimationArray)
      }

      // prefetching all the video or image url in browser to make sure that the video playing is not glitchy
      prefetchAllAssets(jsonData)
      // prefetchAsset(child.src, child.type === 'image' ? 'image/png' : 'video/mp4')

      // prefetching the background music so it plays without lag
      await prefetchAsset(scriptDetails?.backgroundMusic?.url)
      await prefetchAsset(
        scriptDetails?.customTransitionSound ||
          'https://d2tqfxp1334laf.cloudfront.net/image_projects/da764da2-2982-49be-88bd-eca414c4f5d7/assets/audio/e4cdb276-d1ad-4d81-91ae-3182d55c4099/audio.mp3'
      )
      // converting the duration thats in store which is in miliseconds into duration that remotion can understand. Fps is 30, can be changed in the furture
      changeDuration(millisecondsToFrames(store.duration, 30))

      /* this is done so that the caption text on polotno does not show up in the preview video.
    the text shown on polotno when captions is enabled is only shown visually for the user*/
      store.pages.map((page: any) => {
        const elementID = 'captionText' + page.id
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
          })
        }
      })

      await store.pages.map((page: any) => {
        page.children.map((child: any) => {
          if (child.name === 'h1-notlinked') {
            const elementID = child.id
            if (store.getElementById(elementID)) {
              store.getElementById(elementID).set({
                visible: showTextOverlay,
                opacity: showTextOverlay ? 1 : 0,
                // custom.custom:
              })
            }
          }
        })
      })

      // getting the html code
      const result = await  store.toHTML()
      // console.log(store.toJSON())
      const mutedHtml = result.replace(/<video/g, '<video muted')
      // console.log(result)
      // converting the html code into different pages so it reflects each page in json
      const htmlCode = extractPagesFromHtml(removeVideoTags(mutedHtml))

      // adding tags into that htmlCode array so that we can show animations
      const htmlCodeWithTags = addIdsToHtmlFromJson(htmlCode, jsonData)
      await prefetchAsset(scriptDetails?.customTransitionSoundurl)

      changeHtmlCode(htmlCodeWithTags)
      changeWidth(store.width)
      changeHeight(store.height)
      setIsGifRendering(false)
      setStoreUpdatedInBasic(false)
    }
    if (storeUpdatedInBasic) {
      prefetchAndRender()
    }
  }, [storeUpdatedInBasic])

  // useEffect(()=>{
  //
  // },[storeUpdatedInBasic])
  const prefetchAllAssets = async (jsonData: any) => {
    const assetPromises: Promise<void>[] = []

    jsonData.pages.forEach((page: any) => {
      // Prefetch sounds if they exist
      if (Array.isArray(page?.custom?.sounds) && page.custom.sounds.length > 0) {
        assetPromises.push(prefetchAsset(page.custom.sounds[0].url))
      }

      // Prefetch child assets (images and videos)
      page.children.forEach((child: any) => {
        if ((child.type === 'video' || child.type === 'image') && child.src) {
          assetPromises.push(prefetchAsset(child.src))
        }
      })
    })

    // Wait for all assets to prefetch
    await Promise.all(assetPromises)
  }

  //   useEffect(() => {
  //     const indices = getNonAvatarScriptIndex(store, scriptDetails);

  //     setScriptDetails((prevScript: { data: any }) => {
  //       const updatedData = prevScript.data.map((item: any, index: number) => {

  //         if (indices.missingAiAvatarIndices.includes(index)) {

  //           return {
  //             ...item,
  //             aiAvatarProps: {
  //               ...item.aiAvatarProps,
  //               avatarWithBg: false,
  //               avatar: false,
  //               avatarWithWebsite: false,
  //               noAvatar: true,
  //             },
  //           };
  //         }
  //         return item;
  //       });

  //       return { ...prevScript, data: updatedData };
  // });

  //   }, [store]);
  return (
    <Row
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      {/* VideoPlayer Column */}
      <Col
        flex='0 0 auto' // Dynamic width based on content or calculation
        style={{
          width: 'auto', // Allows the content to define the width
          maxWidth: '33vw', // Optional: Maximum width to prevent it from dominating,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '88vh',
            // border: "3px solid var(--color-primary-400)",
            // borderRadius: "10px",
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isGifRendering ? (
            <div
              // center elements vertically
              style={{
                width: `${store.width}px`,
                maxWidth: '26vw',
                height: '88vh',
                borderRadius: '10px',
                border: '3px solid var(--color-primary-400)',
                // height: 'auto',
                // height: store.height,
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                textAlign: 'center',
                marginTop: 'auto',
                flexDirection: 'column',
              }}
            >
              <div>
                <img
                  alt='loader'
                  src='https://d19ep7nqjvgj4d.cloudfront.net/adgen-fe/ai-loader.gif'
                  style={{
                    width: '130px',
                    height: '130px',
                    // margin: 'auto',
                  }}
                />
                <p>Loading Preview...</p>
              </div>
            </div>
          ) : (
            <div
              style={{
                borderRadius: '10px',
                border: '3px solid var(--color-primary-400)',
              }}
            >
              <VideoPlayer
                htmlData={htmlCode}
                duration={duration}
                scriptDetails={scriptDetails}
                jsonData={store.toJSON()}
                transitionDurationInFrames={transitionAnimation[0].duration}
                transitionAnimationProp={transitionAnimation[0].animation}
                isDownload={false}
                burstImagesTransitionAnimationArray={burstImageAnimations}
                videoStyle={videoStyle}
                aiAvatar={userDetails?.purpose?.aiAvatar}
                heightVH={0.8}
                widthVW={0.26}
              />
            </div>
          )}
        </div>
      </Col>

      {/* SectionStoryBoard Column */}
      <Col
        flex='1' // Fills the remaining space
        style={{
          overflow: 'auto', // Handles overflow if content is large
        }}
      >
        <Flex justify='space-between' align='center' style={{ maxHeight: '40px' }}>
          <Title level={2} style={{ paddingInline: '16px', color: 'var(--color-primary-700)' }}>
            (Basic) Video Editor
          </Title>
          <Flex gap={4}>
            {videoRenderStatus.task_id ? (
              <Button
                style={{
                  background: 'var(--gradient-primary)',
                  borderColor: 'transparent',
                  color: 'white',
                }}
                disabled={true}
                loading={true}
              >
                {videoRenderStatus.progressPer}% Rendering
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handleRender()
                }}
                style={{
                  background: 'var(--gradient-primary)',
                  borderColor: 'transparent',
                  color: 'white',
                }}
                disabled={IsVideoRender}
                loading={IsVideoRender}
              >
                Download Video
              </Button>
            )}

            <Button
              onClick={() => {
                handleOk()
              }}
              style={{
                background: 'transparent',
                borderColor: 'var(--color-primary-500)',
                color: 'var(--color-primary-500)',
              }}
            >
              Advanced Video Editor
            </Button>
          </Flex>
        </Flex>
        {/* <p>Hello</p> */}
        <div
          style={{
            display: 'flex',
            overflowX: 'hidden',
            marginLeft: '20px',
            height: 'fit-content',
            // alignItems: "end",
          }}
          // className="custom-scrollbar"
        >
          <SectionStoryBoard store={store} />
        </div>
      </Col>
    </Row>
  )
}

export default observer(PreviewButtonSection)
