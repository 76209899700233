import { Child, JsonData } from '../../../shared/types/polotno'

export function extractPagesFromHtml(htmlString: any) {
  // Correct 'className' to 'class' for HTML parsing
  const correctedHtmlString = htmlString.replace(/className=/g, 'class=')

  // Create a new DOMParser instance
  const parser = new DOMParser()

  // Parse the corrected HTML string into a document object
  const doc = parser.parseFromString(correctedHtmlString, 'text/html')

  // Use querySelectorAll to find all elements with the class 'page'
  const pages = doc.querySelectorAll('.page')

  // Map over the NodeList of pages, and return an array of HTML strings
  const pagesHtml = Array.from(pages).map((page) => page.outerHTML)

  return pagesHtml
}

export function addIdsToHtmlFromJson(htmlStrings: string[], jsonData: JsonData): string[] {
  return htmlStrings.map((htmlString, index) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')

    const pageJson = jsonData.pages[index]
    if (pageJson) {
      pageJson.children.forEach((child: any) => {
        if (child.type === 'text' && child.text) {
          handleTextType(doc, child)
        } else if (child.type === 'image' && child.src) {
          handleImageType(doc, child)
        } else if (child.type === 'svg' && child.width && child.height) {
          handleTextType(doc, child)
        }
        // else if (child.type === 'video' && child.src) { // Handle video type
        //   handleVideoType(doc, child);
      // }
      })
    }

    return doc.body.innerHTML
  })
}

function setIdOnElement(element: Element, id: string): void {
  element.setAttribute('id', id)
}

function normalizeHtmlText(htmlContent: string): string {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlContent
  return tempDiv.textContent || tempDiv.innerText || ''
}

function handleTextType(doc: Document, child: Child): void {
  if (!child.text || child.x === undefined || child.y === undefined) {
    // console.warn('Text or position data undefined for child with ID:', child.id)
    return // Exit the function if essential data is missing
  }

  const normalizedText = normalizeHtmlText(child.text)
  const targetElements = doc.querySelectorAll('div')

  targetElements.forEach((element) => {
    const style = element.getAttribute('style')
    if (style && positionMatches(style, child.x, child.y) && normalizeHtmlText(element.innerHTML) === normalizedText) {
      setIdOnElement(element, child.id)
    }
  })
}

function positionMatches(style: string, x: number, y: number): boolean {
  const leftMatch = style.match(/left:(\s*\d+\.?\d*)px/)
  const topMatch = style.match(/top:(\s*\d+\.?\d*)px/)
  const left = leftMatch ? parseFloat(leftMatch[1]) : null
  const top = topMatch ? parseFloat(topMatch[1]) : null

  return left === x && top === y
}

function handleImageType(doc: Document, child: Child): void {
  const divs = doc.querySelectorAll('div[style]')
  divs.forEach((div) => {
    const style = div.getAttribute('style')!
    if (style.includes(`url(${child.src})`)) {
      setIdOnElement(div, child.id)
    }
  })
}

function handleVideoType(doc: Document, child: Child): void {
  const videos = doc.querySelectorAll('video');
  videos.forEach((video) => {
      if (video instanceof HTMLVideoElement && video.src === child.src) {
          video.setAttribute('preload', 'auto');
          setIdOnElement(video, child.id);
      }
  });
}
export function addPreloadAutoToHTMLArray(htmlDataArray: string[]): string[] {
  const parser = new DOMParser();

  return htmlDataArray.map(htmlData => {
      const doc = parser.parseFromString(htmlData, 'text/html');

      const videos = doc.querySelectorAll('video');
      videos.forEach(video => {
          if (video instanceof HTMLVideoElement) {
              video.setAttribute('preload', 'auto');
          }
      });

      return doc.documentElement.innerHTML;
  });
}


export function removeVideoTags(html:string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const videos = doc.querySelectorAll('video'); // Select all video elements
  videos.forEach(video => {
    video.remove(); // Remove each video element
  });
  return doc.body.innerHTML;
}