import '@blueprintjs/core/lib/css/blueprint.css'
import { Alert, message } from 'antd'
import axios, { AxiosError } from 'axios'
import { getCookie } from 'cookies-next'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno'
import { Workspace } from 'polotno/canvas/workspace'
import {
  unstable_setAnimationsEnabled,
  unstable_setTextVerticalResizeEnabled,
  unstable_useHtmlTextRender,
} from 'polotno/config'
import createStore from 'polotno/model/store'
import { PagesTimeline } from 'polotno/pages-timeline'
import { DEFAULT_SECTIONS, LayersSection, SidePanel } from 'polotno/side-panel'
import { Toolbar } from 'polotno/toolbar/toolbar'
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useGetProject } from './api/useGetProject'
import { IS_FREE_PLATFORM, POLOTNO_KEY, QUICKADS_REDIRECT_URL, REDIRECT_ON } from './config'
import { useGlobalContext } from './context/globalContext'
import { CustomHistoryButtons } from './features/polotno/component/toolBar/historyButtons'
import { ResizeDropdown } from './features/resizeDropdown/resizeDropdown'
import { RightSidebar } from './features/rightSidebar/rightSidebar'
import { CustomVideoTimelineSection } from './features/video/customVideoTimeline/videoTimeline'
import { Loading } from './shared/components/loading'
import { PageLayout } from './shared/components/pageLayout'
import { ScriptToCanvasFunc } from './shared/utils/VideoGeneration/generateVideoHook'
import { handlePutImage, updateProject } from './shared/utils/core'
import { PageControls } from './features/polotno/component/workspace/pageController'
import './App.less'
import { CustomActionControlContainer } from './features/polotno/component/customPolotno/customActionControlContainer'
import { CustomAssetsSection } from './features/polotno/component/customPolotno/customAssetsSidePanel'
import { CustomVideoSearch } from './features/polotno/component/customPolotno/customVideoSearchPanel'
// import { PagesTimeline } from 'polotno/pages-timeline';
/* When you enable animations, polotno will add additional UI in the toolbar to change animation properties of the selected object.*/
import { ScriptToCanvasBurst } from './shared/utils/VideoGeneration/BurstScriptToCanvas'
import { ScriptToCanvas } from './shared/utils/VideoGeneration/ScriptToCanvas'
// import { trackEvent } from 'src/lib/mixpanel'
import IsMobileModal from './shared/components/isMobileModal'
import { customVideoVariations } from './features/video/videoVariations/videoVariations'
import { resizeDesign } from './shared/utils/polotno/resizing'
import { trackEvent } from './lib/mixpanel'
import { testScriptresponse } from './data/testOutput'
import { preloadAssets } from './shared/utils/preloadAssetsInClass'
import { useGetDomainDetails } from './api/useGetDomainDetails'
import { useGetUserDeails } from './api/useGetUserDetails'
import { generatePrimaryShades } from './shared/utils/generatePallate'
import {
  creditsDeduct,
  gettyAssetTrack,
  updateWaterMarkVideos,
} from './shared/utils/VideoGeneration/helperFunctions/mediaSearch'
import { initializeEventSource } from './shared/utils/VideoGeneration/helperFunctions/events'
import { domainLogout } from './config/config'
import { AnimationDropdown } from './features/animation/animationSelect'
import { CustomTextPanelPolotno } from './features/polotno/component/polotnoTextPanel'
import { CustomElementPolotno } from './features/polotno/component/customPolotno/customElementsPanel'
import { CustomAudioPolotno } from './features/rightSidebar/audioPanel'
import { CustomCaptionPolotno } from './features/rightSidebar/captions/captionsPanel'
import { CustomTransitionPolotno } from './features/video/customVideoTimeline/cardRows/transitionRow'
import { CustomThumbnailPolotno } from './features/video/customVideoTimeline/cardRows/thumbnailPanel'
import SceneSettings from './shared/components/sceneSettings/sceneSettings'
import { CustomVideoSearchTwo } from './features/polotno/component/customPolotno/customVideoSearchPanelTwo'
import { CustomAdvancedPanel } from './features/polotno/component/advancedSetting'
import { getAiAvatarVideo } from './shared/utils/VideoGeneration/helperFunctions/tavusApi'
import FullScreenLoader from './shared/components/loaderOverlay'
import { getVideoDuration } from 'polotno/utils/video'
import Settings from './shared/components/sceneTab/Settings'
import { checkVideoGeneratedStatus, generateSnapShot } from './shared/utils/VideoGeneration/helperFunctions/webSnapShot'
import PreviewButtonSection from './features/polotno/component/previewStory'
import { checkRenderStatus } from './shared/utils/VideoGeneration/helperFunctions/aiAvatarAsync'
import { triggerDownload } from './shared/utils/VideoGeneration/AssetDownload'
import { loadFontsCustom } from './shared/utils/loadfonts'
// import { SceneSettings } from './shared/components/sceneSettings/sceneSettings'
unstable_setAnimationsEnabled(true)

/* By default, you can't change height of text elements. Texts are always updating its own height to fit its content. 
When vertical resize is enabled you can also set vertical align from the text toolbar.
New polotno update has provided us with new features which can be paired with this. 
Kindly visit https://polotno.com/docs/text-overflow  for new features that can be used*/
unstable_setTextVerticalResizeEnabled(true)

/*Out of the box, Polotno does not support the rendering of rich text elements, meaning you cannot apply different styles, 
colors, fonts, etc., to parts of the text. The entire text element must maintain a uniform style. 
However, Polotno offers experimental support for rich text elements, which must be explicitly activated.*/
unstable_useHtmlTextRender(true)

const store = createStore({
  key: `${POLOTNO_KEY}`,
  showCredit: false,
})

const getVideoStyle = (tone: string, script: any): string => {
  if (tone === 'Professional') {
    if (!script.data[0].searchQueries) {
      return 'Burst'
    }
    return 'Simple'
  }
  if (tone === 'Upbeat') {
    if (script.data[0].images) {
      return 'Burst'
    }
    return 'Simple'
  }
  return 'Simple'
}

// Remove sections that are not needed in the left side panel.
const EmptySection = {
  name: 'videos',
  Tab: () => null,
  Panel: () => null,
}
const sectionsToRemove = ['text', 'templates', 'size', 'elements', 'background', 'photos', 'size', 'layers']
let sections = [
  CustomVideoSearchTwo as any,
  EmptySection as any,
  CustomAudioPolotno as any,
  CustomElementPolotno as any,
  CustomTransitionPolotno as any,
  // "layers" as any,
  LayersSection as any,
  CustomAdvancedPanel as any,
]
if (IS_FREE_PLATFORM === 'false') {
  sectionsToRemove.push('upload')
  // sections.push(CustomAssetsSection as any)
}
const AdvanedSections = [
  CustomCaptionPolotno as any,
  CustomTextPanelPolotno as any,
  // customVideoVariations as any,
  CustomThumbnailPolotno as any,
]
sections.push(...DEFAULT_SECTIONS.filter((section) => !sectionsToRemove.includes(section.name)))

/* loading the initial json from the project and loading it into the store
Load passed json into the store. It will update all properties, pages and elements. 
By default loading JSON into the store will reset undo/redo history (so a user can't undo it). 
You can use keepHistory argument, if you want to preserve the history */
const LoadInitialJson = async (store: any, json: any) => {
  if (json) {
    await store.loadJSON(json, false)
  }
}

type DecodedData = {
  token: string
  email: string
  name: string
  status: string
  createdAt: string
  updatedAt: string
}

// Decodes a URI-encoded cookie value and parses it into a JSON object
export const decodeAndParseCookie = (cookieValue: any): DecodedData => {
  const decodedString = decodeURIComponent(cookieValue)
  const data: DecodedData = JSON.parse(decodedString)
  return data
}

export const App = () => {
  const { data: domainData } = useGetDomainDetails()
  const projectID = location.pathname.split('/').pop()
  const [, setPageLoading] = useState(true)
  const [, setFirstRender] = useState(false)
  const [open, setOpen] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [bulkCreatePropsArray, setBulkCreatePropsArray] = useState<any[]>([])
  const sidebarRef = useRef<HTMLDivElement>(null)
  const [avatarLoader, setAvatarLoader] = useState(false)
  const [snapShotRequestCount, setSnapShotRequestCount] = useState(0)
  const renderCount = useRef(0)
  renderCount.current += 1
  // const [showAdvancedEditor, setShowAdvancedEditor] = useState(false);
  // const [progressBarPercent, setProgressBarPercent] = useState<number>(0)
  useEffect(() => {
    console.log(`Component rendered ${renderCount.current} times`)
  })
  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    BackgroundMusic: [, setBackgroundMusic],
    IsLoading: [isLoading, setIsLoading],
    RegeneratedScriptDetails: [, setRegeneratedScriptDetails],
    VideoStyle: [, setVideoStyle],
    UndoStack: [, setUndoStack],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
    VideoMediaType: [, setVideoMediaType],
    ProgressPercent: [progressBarPercent, setProgressBarPercent],
    RightSideBar: [activeComponent, setActiveComponent],
    CurrentUserData: [currentUserData, setCurrentUserData],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    AvatarTasks: [avatarTasks, setAvatarTasks],
    ShowAdvanced: [showAdvanced, setShowAdvanced],
    SnapShotsTasks: [snapShotsTasks, setSnapShotsTasks],
    ShowAdvancedEditor: [showAdvancedEditor, setShowAdvancedEditor],
    VideoRenderStatus: [videoRenderStatus, setVideoRenderStatus],
    PreviewLoader: [, setIsPreviewLoading],
  } = useGlobalContext()

  useEffect(() => {
    if (showAdvanced) {
      sections.push(...AdvanedSections)
      store.openSidePanel('caption-panel')
    } else {
      const sectionsToRemovee = AdvanedSections.map((item) => item.name)
      sections = sections.filter((section) => !sectionsToRemovee.includes(section.name))
      store.openSidePanel('audio-panel')
    }
  }, [showAdvanced])

  const { data: projectData, error: projectError } = useGetProject(projectID)
  const { data: userData, error: userError } = useGetUserDeails(localStorage.getItem('accessToken'))
  //***white labeling **//
  useEffect(() => {
    if (userData) setCurrentUserData(userData)
  }, [userData])
  useEffect(() => {
    const changeFavicon = (url: string) => {
      let link = document.querySelector('link[rel*="icon"]') as HTMLLinkElement
      if (!link) {
        link = document.createElement('link') as HTMLLinkElement
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = url
    }

    changeFavicon(domainData?.fav_icon_url)
    document.title = domainData?.brand_name?.charAt(0)?.toUpperCase() + domainData?.brand_name?.slice(1) || ' -- '

    if (domainData) {
      if (domainData?.domain_name?.includes('quickads')) {
        const element = document.querySelector('.editor-container .polotno-side-tabs-container') as HTMLDivElement
        if (element) {
          const styleElement = document.createElement('style')
          styleElement.innerHTML = `
            .editor-container .polotno-side-tabs-container {
              background-image: url("https://d19ep7nqjvgj4d.cloudfront.net/adgen-fe/editor_sidebar_bg.png") !important;
            }
          `
          document.head.appendChild(styleElement)
        }
      } else {
        // const shades = generatePrimaryShades('#008000');
        const shades = generatePrimaryShades(domainData?.colors?.primary || '#7A5AF8')
        const root = document.documentElement
        root.style.setProperty('--color-primary-50', shades[50])
        root.style.setProperty('--color-primary-100', shades[100])
        root.style.setProperty('--color-primary-200', shades[200])
        root.style.setProperty('--color-primary-300', shades[300])
        root.style.setProperty('--color-primary-400', shades[400])
        root.style.setProperty('--color-primary-500', shades[500])
        root.style.setProperty('--color-primary-600', shades[600])
        root.style.setProperty('--color-primary-700', shades[700])
        root.style.setProperty('--color-primary-800', shades[800])
        root.style.setProperty('--color-primary-900', shades[900])
        root.style.setProperty('--color-gradStart', shades[300])
        root.style.setProperty('--color-gradEnd', shades[500])
      }
    }
  }, [domainData])

  // ****** Resize on first load hook function ****** //
  async function resizeOnFirstLoad(projectDataProp: any, userDetails: any, sizeString?: string) {
    const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

    await resizeDesign(oldJSON, store, userDetails, setUserDetails, true, projectDataProp, sizeString)
  }
  const specificContainerRef = useRef<HTMLDivElement>(null)
  const handleToggleComponent = (componentName: string) => {
    if (activeComponent === componentName) {
      setActiveComponent('')
    } else {
      setActiveComponent(componentName)
    }
  }
  const handleClickOutside = (event: MouseEvent) => {
    // if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
    //   // setIsVisible(false);
    //   // handleToggleComponent(activeComponent)
    //   return
    // }

    if (specificContainerRef.current && specificContainerRef.current.contains(event.target as Node)) {
      // If click is inside specific container, call the function
      handleToggleComponent(activeComponent)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  // Handles errors from project data fetching based on HTTP status codes and manages redirection or authentication cleanup
  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      if (projectError && axios.isAxiosError(projectError)) {
        const axiosError = projectError as AxiosError
        message.error('Error fetching project data: ' + axiosError.message)

        const statusCode = axiosError.response ? axiosError.response.status : null

        if (statusCode === 400 || statusCode === 404) {
          if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') {
            domainLogout()
          }
        }

        if (statusCode === 403) {
          if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') {
            domainLogout()
          }
        }

        if (statusCode === 406) {
          if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') {
            domainLogout()
          }
        }
      }
    }
  }, [projectError])

  // Set initial states and generate video once projectData response is recieved
  useEffect(() => {
    async function getData() {
      if (projectData && IS_FREE_PLATFORM === 'false') {
        setIsLoading(true)
        const jsonDataRes = projectData?.json_body
        const videoStyle = getVideoStyle(projectData?.purpose?.tone, projectData?.script) || 'Simple'
        localStorage.setItem('style', videoStyle)
        localStorage.setItem('Prompt', projectData?.purpose?.promptToVideo)
        localStorage.setItem('graphicalStyle', projectData?.purpose?.graphicalStyle)

        const videoMediaType = projectData?.purpose?.mediaType || 'Images'
        LoadInitialJson(store, jsonDataRes)
        setUserDetails(projectData)
        // setScriptDetails(testScriptresponse)
        setScriptDetails(projectData?.script)
        setRegeneratedScriptDetails(projectData?.script?.data)
        setFirstRender(projectData?.first_render)
        setVideoStyle(videoStyle)
        // setUndoStack([projectData?.script]);
        setIsStoreUpdated(!isStoreUpdated)
        setVideoMediaType(videoMediaType)
        localStorage.setItem('aspect_ratio', projectData?.purpose?.size)
        if (projectData?.first_render) {
          ScriptToCanvasFunc(
            setPageLoading,
            projectData?.script,
            projectData?.first_render,
            projectData?.purpose?.industry === undefined || projectData?.purpose?.industry === 'undefined'
              ? 'Others'
              : projectData?.purpose?.industry,
            setBackgroundMusic,
            setScriptDetails,
            setProgressBarPercent,
            projectData?.logo,
            projectData?.purpose?.size,
            videoStyle,
            videoMediaType,
            store,
            projectData?.colors?.secondary || '',
            projectData,
            setShowTextOverlay,
            avatarTasks,
            setAvatarTasks,
            setSnapShotsTasks
          ).then(() => {
            resizeOnFirstLoad(projectData, userDetails, projectData?.purpose?.size).then(() => {
              if (projectData?.purpose?.aiAvatar) {
                if (!avatarLoader) {
                  // setPreviewOpen(true);
                  setIsLoading(false)
                  setShowAdvancedEditor(false)
                } else {
                  // setPreviewOpen(false);
                  setIsLoading(true)
                }
              } else {
                setIsLoading(false)
                setShowAdvancedEditor(false)
                // setPreviewOpen(true);
              }
            })
          })
        } else {
          // console.log(projectData?.script)
          if (projectData?.script?.backgroundMusic?.url) {
            if (projectData?.script?.backgroundMusic?.url?.includes('typeframes')) {
              const backgroundScript = {
                url: 'https://daqrukw5ofwla.cloudfront.net/public_element/79970/element.wav',
                duration: 30,
                industry: 'Others',
                name: 'Deep Dream',
                backgroundVolume: 0.1,
              }
              await setScriptDetails((prevScript: { data: any }) => {
                const updatedScript = { ...prevScript }
                return { ...updatedScript, backgroundMusic: backgroundScript }
              })
            } else {
              console.error('The URL does not contain "typeframe".')
            }
          } else {
            const backgroundScript = {
              url: 'https://daqrukw5ofwla.cloudfront.net/public_element/79970/element.wav',
              duration: 30,
              industry: 'Others',
              name: 'Deep Dream',
              backgroundVolume: 0.1,
            }
            await setScriptDetails((prevScript: { data: any }) => {
              const updatedScript = { ...prevScript }
              return { ...updatedScript, backgroundMusic: backgroundScript }
            })
          }
          // if(!projectData?.purpose?.aiAvatar){
          await updateWaterMarkVideos(store)
          // }

          resizeOnFirstLoad(projectData, userDetails, projectData?.purpose?.size).then(() => {
            // setPreviewOpen(true);
            setIsLoading(false)
            // setShowAdvancedEditor(false);
          })

          setIsLoading(false)
          setShowAdvancedEditor(false)
        }

        store.history.clear()
        //
      }
      await loadFontsCustom(store)
    }
    getData()
  }, [projectData])

  // useEffect(()=>{
  //   if(progressBarPercent>92){
  //     setIsLoading(false);
  //     setPreviewOpen(true);
  //   }
  // },[progressBarPercent])

  // Set initial states and generate video for the free version
  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      // const token = getCookie("crossToken");
      // if (token) {
      //   const data = decodeAndParseCookie(token);
      //   localStorage.setItem("userData", JSON.stringify(data));
      // }
    } else {
      const script = getCookie('scriptData')
      const size = getCookie('projectSize')
      const industry = getCookie('projectIndustry')
      const style = getCookie('projectStyle')
      if (script) {
        const data = decodeAndParseCookie(script)
        const scriptData = { data: JSON.parse(script) }
        localStorage.setItem('scriptData', JSON.stringify(data))
        setScriptDetails(scriptData)
        setRegeneratedScriptDetails(scriptData)
        setVideoStyle(style ? style.toString() : 'Simple')
        ScriptToCanvasFunc(
          setPageLoading,
          scriptData,
          true,
          industry ? industry.toString() : 'Fashion',
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          '',
          size ? size.toString() : '9:16',
          style ? style.toString() : 'Simple',
          'Images',
          store,
          '',
          projectData,
          setShowTextOverlay,
          avatarTasks,
          setAvatarTasks,
          setSnapShotsTasks
        ).then(() => {
          resizeOnFirstLoad(projectData, userDetails, size ? size.toString() : '9:16').then(() => {
            setIsLoading(false)

            // setPreviewOpen(true);
          })
        })
      }
    }
  }, [])

  // Call handlePutImage once 10 seconds after the page loads to save preview of the project
  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      const timer = setTimeout(() => {
        handlePutImage(projectID, store)
      }, 10000)
      return () => clearTimeout(timer)
    }
  }, [isLoading])

  useEffect(() => {
    const timer = setTimeout(() => {
      trackEvent('Free Video Ad Generated')
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const saveData = () => {
      if (!userDetails?.first_render && store.toJSON()?.pages.length > 0) {
        handlePutImage(projectID, store)
        updateProject(userDetails, store, scriptDetails)
      }
    }

    // Run saveData every 10 seconds
    const intervalId = setInterval(saveData, 10000) // 10000 ms = 10 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId)
  }, [userDetails, store, scriptDetails])

  useEffect(() => {
    if (progressBarPercent >= 90) {
      handlePutImage(projectID, store)
      updateProject(userDetails, store, scriptDetails)

      if (userData?.voice_limit == 1) {
        creditsDeduct('voice_credits', 1)
        return
      }
      // const totalDurationInSeconds = scriptDetails.data.reduce((sum:number, item:any) => sum + item?.sounds?[0]?.audioDuration || 0, 0);
      const totalDurationInSeconds = scriptDetails.data.reduce((sum: number, item: any) => {
        return sum + (item.sounds && item.sounds.length > 0 ? item.sounds[0].audioDuration : 0)
      }, 0)
      const audioDurationInMins = Math.ceil(totalDurationInSeconds / 60)
      creditsDeduct('voice_credits', audioDurationInMins)
    }
  }, [progressBarPercent])

  const pollingInterval = 5000 // Poll every 5 seconds
  // Function to check the status of each task in the list
  const checkSnapShotStatus = async () => {
    try {
      const updatedTasks = await Promise.all(
        snapShotsTasks.map(async (task) => {
          if (task.status === 'Done') return task
          const statusReponse = await checkVideoGeneratedStatus(task.taskId)
          let taskStatus = statusReponse?.status
          const videoUrl = statusReponse?.data?.image_url || ''
          let task_id = ''
          if (taskStatus === 'Done') {
            const element = store.getElementById(task.elementId)
            if (element) {
              await element.set({
                src: videoUrl,
              })
            }
            trackEvent('Video', {
              name: currentUserData?.name,
              email: currentUserData?.email,
              message: `Website snapshot success for project id: ${projectID}, Website: ${task.backgroundWebiste}`,
              type: 'INFO',
              submodule: 'Website_Snapshot',
            })
          } else if (taskStatus === 'Error') {
            if (snapShotRequestCount < 1) {
              const snap_orientations =
                projectData?.purpose?.size === '9:16' || projectData?.purpose?.size === '1:1'
                  ? 'vertical'
                  : 'horizontal'
              const snapShotResponse = await generateSnapShot(task.backgroundWebiste, snap_orientations)
              task_id = snapShotResponse?.task_id
              taskStatus = snapShotResponse?.status
              setSnapShotRequestCount((prev) => prev + 1)
              trackEvent('Video', {
                name: currentUserData?.name,
                email: currentUserData?.email,
                message: `Website snapshot failed for project id: ${projectID}, Website: ${task.backgroundWebiste}`,
                type: 'ERROR',
                submodule: 'Website_Snapshot',
              })
            } else {
              message.warning('Website snapshot failed. Please upload manually', 10)
            }
          }
          if (task_id) {
            return { ...task, status: taskStatus, videoUrl: videoUrl, taskId: task_id }
          } else {
            return { ...task, status: taskStatus, videoUrl: videoUrl }
          }
        })
      )
      setSnapShotsTasks(updatedTasks)
    } catch (error) {
      console.error('Error checking statuses:', error)
    }
  }
  const checkStatuses = async () => {
    try {
      const updatedTasks = await Promise.all(
        avatarTasks.map(async (task) => {
          if (task.taskStatus === 'ready' || task.taskStatus === 'error') return task // Skip tasks already ready
          if (task.taskStatus === 'error') {
            console.error(task)
          }
          const response = await getAiAvatarVideo(task.videoId) // Replace with your status API endpoint
          // console.log(response)
          const taskStatus = response?.status
          const downloadUrl = response.download_url || ''
          if (taskStatus === 'ready') {
            const element = store.getElementById(task.elementId)
            let duration = 2
            const videoDuration = await getVideoDuration(downloadUrl)
            duration = typeof videoDuration === 'number' ? videoDuration : duration

            const newDuration =
              duration * 1000 > store.pages[task.scriptIndex].duration
                ? duration * 1000 + 500
                : store.pages[task.scriptIndex].duration + 500
            // if (task.scriptIndex === store.pages.length - 1) {
            //   newDuration = newDuration + 1000
            // }
            if (element) {
              await element.set({
                src: downloadUrl,
              })
            }
            store.pages[task.scriptIndex].set({
              duration: task.lessThanThreeSec ? newDuration / 2 - 1500 : newDuration - 1500,
            })

            // console.log(task.lessThanThreeSec ?(newDuration/2)-1000 : newDuration-1000)
          }

          return { ...task, taskStatus: taskStatus, outPutUrl: downloadUrl } // Update task status
        })
      )
      setUserDetails((prev: any) => ({
        ...prev,
        purpose: {
          ...prev.purpose,
          avatarIds: [updatedTasks],
        },
      }))

      setAvatarTasks(updatedTasks)
      // setAvatarTasks()
      // setTasks(updatedTasks); // Update the tasks array with new statuses
    } catch (error) {
      console.error('Error checking statuses:', error)
    }
  }

  //---------------------------Check the download render status-------------
  useEffect(() => {
    if (!videoRenderStatus.task_id) return
    setIsPreviewLoading(true)
    // Function to poll the API every 5 seconds
    const pollApi = async () => {
      try {
        const status = await checkRenderStatus(videoRenderStatus.task_id)
        // if(status.status == 'In Progress'){
        if (status?.status === 'Done') {
          setIsPreviewLoading(false)
          store.set({
            custom: {
              remotionUrl: status?.data?.final_video_url,
              lastRendered: Date.now(),
            },
          })

          trackEvent('Video', {
            name: currentUserData?.name,
            email: currentUserData?.email,
            message: `Download Successfull for project id: ${projectID}`,
            type: 'INFO',
            submodule: 'Video_Download_Success',
            variant: userDetails?.purpose?.flow || ''
          })

          setVideoRenderStatus({
            video_url: status.data.final_video_url,
            status: '',
            task_id: '',
            progressPer: status.data?.video_progress,
          })
          message.success('Rendered Successfully')
          triggerDownload(status.data.final_video_url, 'FinalVideo.mp4')
        } else if (status?.status === 'Error') {
          setIsPreviewLoading(false)
          setVideoRenderStatus({
            video_url: '',
            status: '',
            task_id: '',
            progressPer: status.data?.video_progress,
          })
          trackEvent('Video', {
            name: currentUserData?.name,
            email: currentUserData?.email,
            message: `Video download failed for project id: ${projectID}`,
            type: 'ERROR',
            submodule: 'Video_Download_Success',
            variant: userDetails?.purpose?.flow || ''
          })
          message.error('Render failed! Please make every avatar scene has minimum duration of 3 seconds')
        } else {
          setVideoRenderStatus({
            video_url: '',
            status: 'In Progress',
            task_id: videoRenderStatus.task_id,
            progressPer: status.data?.video_progress,
          })
        }
        // }else if(status.status !== 'Done'){
        //   message.error('Render Failed')
        // }
        // Stop polling if video_url is available
        if (status?.data?.final_video_url) {
          clearInterval(intervalId)
        }
      } catch (error) {
        console.error('Error while polling:', error)
      }
    }

    // Set interval for polling
    const intervalId = setInterval(pollApi, 5000)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId)
  }, [videoRenderStatus])

  // Start task creation interval and polling interval when component mounts
  useEffect(() => {
    const completedTasks = avatarTasks.filter((task: any) => task.taskStatus !== 'ready' && task.taskStatus !== 'error')

    if (completedTasks.length > 0) {
      // Interval to check the status of all tasks every `pollingInterval`
      const pollingTaskInterval = window.setInterval(() => {
        checkStatuses()
      }, pollingInterval)
      // Cleanup intervals on component unmount
      return () => {
        // clearInterval(createTaskInterval);
        clearInterval(pollingTaskInterval)
      }
    }
  }, [avatarTasks])

  useEffect(() => {
    const completedSnapShotTasks = snapShotsTasks.filter((task: any) => task.status !== 'Done')
    if (completedSnapShotTasks.length > 0) {
      // Interval to check the status of all tasks every `pollingInterval`
      const pollingTaskInterval = window.setInterval(() => {
        checkSnapShotStatus()
      }, pollingInterval)
      // Cleanup intervals on component unmount
      return () => {
        // clearInterval(createTaskInterval);
        clearInterval(pollingTaskInterval)
      }
    }
  }, [snapShotsTasks])
  return (
    <>
      {domainData && (
        <>
          {isMobile && <IsMobileModal />}
          {isLoading && <Loading percentage={progressBarPercent} />}
          <PageLayout
            heading={'Social Media Post Generator'}
            domainData={domainData}
            store={store}
            open={open}
            setOpen={setOpen}
            bulkCreatePropsArray={bulkCreatePropsArray}
            setBulkCreatePropsArray={setBulkCreatePropsArray}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
            setProgressBarPercentage={setProgressBarPercent}
          >
            <div onContextMenu={(e) => e.preventDefault()}>
              {showAdvancedEditor ? (
                <PolotnoContainer style={{ width: '100%', height: '93vh' }}>
                  <SidePanelWrap>
                    <SidePanel store={store} sections={sections} defaultSection='video-editor' />
                  </SidePanelWrap>
                  <WorkspaceWrap>
                    <Toolbar
                      store={store}
                      components={{
                        History: () => <CustomHistoryButtons store={store} />,
                        ActionControls: () => (
                          <>
                            <CustomActionControlContainer
                              store={store}
                              open={open}
                              setOpen={setOpen}
                              bulkCreatePropsArray={bulkCreatePropsArray}
                              setBulkCreatePropsArray={setBulkCreatePropsArray}
                              previewOpen={previewOpen}
                              setPreviewOpen={setPreviewOpen}
                              setProgressBarPercentage={setProgressBarPercent}
                            />
                          </>
                        ),
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        flexDirection: 'row',
                        background: 'transparent',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          background: '#EAECF0',
                        }}
                        ref={specificContainerRef}
                      >
                        <ResizeDropdown store={store} />
                        <AnimationDropdown store={store} />

                        {/* <div ref={specificContainerRef}> */}
                        <Workspace
                          store={store}
                          pageBorderColor='white'
                          activePageBorderColor='#7735BD'
                          backgroundColor='#EAECF0'
                          components={{ PageControls }}
                          renderOnlyActivePage={true}
                        />
                        {/* </div> */}

                        {/* <PagesTimeline store={store} defaultOpened /> */}
                        {/* <SceneSettings store={store}/> */}
                        <div
                          style={{
                            background: 'white',
                            border: '1px solid lightgrey',
                            borderRadius: '10px',
                            padding: '10px',
                            margin: '10px',
                            overflow: 'scroll',
                          }}
                        >
                          <Settings store={store} />
                        </div>
                        <ZoomButtons store={store} />
                      </div>
                    </div>
                  </WorkspaceWrap>
                </PolotnoContainer>
              ) : (
                <WorkspaceWrap>
                  <Workspace
                    store={store}
                    pageBorderColor='white'
                    activePageBorderColor='#7735BD'
                    backgroundColor='#EAECF0'
                    components={{ PageControls }}
                  />
                  <PreviewButtonSection store={store} />
                </WorkspaceWrap>
              )}
            </div>
            <FullScreenLoader loading={avatarLoader} />
          </PageLayout>
        </>
      )}
    </>
  )
}
