import { getDomainID, qaBackendApiInstance } from '../config/config'
import { useQuery } from 'react-query'

// for staff names
export const useGetUserDeails = (accessToken?: string | undefined | null) => {
  const domain_id = getDomainID()
  return useQuery(
    ['useGetUserDeails', accessToken],
    async () => {
      const userData = await qaBackendApiInstance.get(`/user/details?domain_id=${domain_id}`).catch((error) => {
        throw error
      })
      return userData?.data
    },
    { enabled: !!accessToken, retry: false }
  )
}
