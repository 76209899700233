import React, { useEffect, useRef } from 'react'
import { AbsoluteFill, Img, OffthreadVideo, staticFile, useCurrentFrame, useVideoConfig, Video } from 'remotion'
import { applyAnimations, calculateTrimTimes, prefetchAsset } from '../../../shared/utils/remotion'
import { Gif } from '@remotion/gif'
interface Animation {
  delay: number
  duration: number
  enabled: boolean
  type: string
  name: string
  data: any
}

interface Child {
  custom: any
  id: string
  type: string
  text?: string
  animations: Animation[]
  src?: string
  x: number
  y: number
  width: number
  height: number
  rotation: number
  fontFamily?: string
  fontSize?: number
  fontWeight?: string
  fontStyle?: string
  textAlign?: string
  fill?: string
  cornerRadius?: number
  borderSize?: number
  cropX: number
  cropY: number
  blurEnabled: boolean
  blurRadius: number
  sepiaEnabled: boolean
  grayscaleEnabled: boolean
  brightnessEnabled: boolean
  brightness: number
  shadowEnabled: boolean
  shadowBlur: number
  letterSpacing: number
  strokeWidth: number
  stroke: string
  lineHeight: number
  cropWidth: number
  cropHeight: number
  duration: number
  startTime: number
  endTime: number
}

interface PageData {
  id: string
  children: Child[]
}

interface SinglePageProps {
  pageHtml: any // HTML string for the page content
  width: number
  height: number
  pageData: PageData // Adjusted to pass JSON data
  isDownload: boolean
  aiAvatar: boolean
  overlayAnimation: boolean
  webScroll: boolean
}

export const SinglePage: React.FC<SinglePageProps> = React.memo(
  ({ pageHtml, width, height, pageData, isDownload, aiAvatar, overlayAnimation, webScroll }: SinglePageProps) => {
    const frame = useCurrentFrame()
    const { fps, durationInFrames } = useVideoConfig()
    const pageRef = useRef<HTMLDivElement>(null)
    // let pageHtmlRemovedElement=pageHtml
    useEffect(() => {
      applyAnimations(pageRef, pageData, frame, fps, durationInFrames, width, height)
    }, [frame, pageData])

    useEffect(() => {
      async function prefetch() {
        await Promise.all(
          pageData?.children.map(async (child: any) => {
            // Prefetch for all children in parallel
            child.type === 'video' || (child.type === 'image' && child.src)
              ? prefetchAsset(child.src, child.type === 'image' ? 'image/png' : 'video/mp4')
              : Promise.resolve()
          })
        )
      }
      prefetch()
    }, [])

    const scaleFactor = isDownload ? 1 : Math.min(width / 1 / width, height / 1 / height)

    return (
      <AbsoluteFill>
        <AbsoluteFill style={{ position: 'relative' }}>
          {pageData?.children.map((child, index) => {
            if (child.type === 'video' && child.src) {
              const cropStyle: React.CSSProperties = {
                position: 'absolute',
                overflow: 'hidden',
                left: `${child.x * scaleFactor}px`,
                top: `${child.y * scaleFactor}px`,
                width: isDownload ? `${child.width}px` : `${child.width * scaleFactor}px`,
                height: isDownload ? `${child.height}px` : `${child.height * scaleFactor}px`,
                transform: `rotate(${child.rotation}deg)`,
              }

              const trimmedDuration = calculateTrimTimes(
                child.duration || child?.custom?.duration || 20000,
                child.startTime || child?.custom?.startTime || 0,
                child.endTime || child?.custom?.endTime || 1,
                fps
              )

              const videoStyle: React.CSSProperties = {
                position: 'absolute',
                width: `${(1 / child.cropWidth) * 100}%`,
                height: `${(1 / child.cropHeight) * 100}%`,
                objectFit: 'cover',
                transform: `translate(-${child.cropX * 100}%, -${child.cropY * 100}%)`,
              }

              // const isMute = (!isDownload ? true : aiAvatar ? false : true) && !child?.custom?.aiAvatar;
              const isMute = !isDownload ? true : child.custom?.aiAvatar ? false : true
              /*
                if not download:
                     mute
                else:
                  if child.custom.aiavatar:
                    dont mute
                  else:
                    mute
                  
                */
              return (
                <div key={child.id} style={cropStyle}>
                  <OffthreadVideo
                    src={child.src}
                    // src={staticFile("08e4f45e.webm")}
                    style={videoStyle}
                    startFrom={trimmedDuration.startTime}
                    muted={child.duration < 3500 ? true : isMute}
                    transparent={child?.custom?.bg && !isMute}
                    // pauseWhenBuffering={true}
                  />
                </div>
              )
            } else if (child.type === 'image' && child.src) {
              const cropStyle: React.CSSProperties = {
                position: 'absolute',
                overflow: 'hidden',
                left: isDownload ? `${child.x}px` : `${child.x * scaleFactor}px`,
                top: isDownload ? `${child.y}px` : `${child.y * scaleFactor}px`,
                width: isDownload ? `${child.width}px` : `${child.width * scaleFactor}px`,
                height: isDownload ? `${child.height}px` : `${child.height * scaleFactor}px`,
                transform: `rotate(${child.rotation}deg)`,
              }

              // Interpolating scroll position based on the current frame
              const totalFrames = durationInFrames // Total frames for the animation
              const startY = 0 // Starting position of the image
              const endY = -child.height * scaleFactor // End position for the image scrolling

              // Calculate the vertical translation
              const currentScroll = Math.max(endY, startY + ((endY - startY) * frame) / totalFrames)

              const imageStyle: React.CSSProperties = {
                position: 'absolute',
                width: `${(1 / child.cropWidth) * 100}%`,
                height: `${(1 / child.cropHeight) * 100}%`,
                objectFit: 'cover',
                transform: webScroll
                  ? `translate(-${child.cropX * 100}%, -${child.cropY * 100}%) translateY(${currentScroll}px)`
                  : `translate(-${child.cropX * 100}%, -${child.cropY * 100}%)`,
              }

              return (
                <div key={child.id} style={cropStyle}>
                  <Img src={child.src} style={imageStyle} />
                </div>
              )
            }
            return null
          })}
        </AbsoluteFill>

        {/* Page HTML Content */}
        <div
          ref={pageRef}
          style={{
            position: 'absolute',
            transform: isDownload ? 'none' : `scale(${scaleFactor})`,
            transformOrigin: 'top left',
            width: `${width}px`,
            height: `${height}px`,
            // zIndex: 1,
          }}
          dangerouslySetInnerHTML={{ __html: pageHtml }}
        />
        {overlayAnimation && (
          <AbsoluteFill>
            <OffthreadVideo
              src={staticFile('remotionPartical/particle_3.webm')}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              muted={true}
              transparent={true}
              // loop={true}
            />
          </AbsoluteFill>
        )}
      </AbsoluteFill>
    )
  }
)

SinglePage.displayName = 'SinglePage'
