import {
  RedoOutlined,
  UploadOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { useGetGettyImageVideos } from "../../../../api/gettyImages/getGettyImageVideo";
import { useGetGettyImages } from "../../../../api/gettyImages/getGettyImages";
import { useGetPexelsVideos } from "../../../../api/pexels/getPexelsVideo";
import { useGetAssets } from "../../../../api/useGetAssets";
import { PEXELS_KEY, PIXABY_KEY, IS_FREE_PLATFORM } from "../../../../config";
import { qaBackendApiInstance, getDomainID } from "../../../../config/config";
import { useGlobalContext } from "../../../../context/globalContext";
import { Button, Input, Tabs, message, Collapse, Select } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { observer } from "mobx-react-lite";
import { SectionTab } from "polotno/side-panel";
import { useCallback, useEffect, useRef, useState } from "react";
import { VideoSearch } from "../videoSearchResultTwo";
import { v4 as uuidv4 } from "uuid";
import { useGetAiImages } from "../../../../api/useAiImageAssets";
import { generateUniqueId } from "../../../../shared/utils/core";
import {
  Alignment,
  Button as BlueprintButton,
  Navbar,
} from "@blueprintjs/core";
import { aiImagesAPI } from "@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch";
import TextArea from "antd/es/input/TextArea";
import { useFileUploadServer } from "@src/api/videoUpload/useFileUploadServer";
import { Video02Icon } from "../hugeIcons/hugeIcons";
import useGetAroleAssets from "@src/api/useGetARoleAssts";
import { useMultiPartServerS3 } from "@src/api/useMultiparts3Upload";

const { Panel } = Collapse;

interface VideoPanelProps {
  store: any;
  elementID: string;
  index: number;
  isNotPanel?: boolean;
  searchQueries: string[];
}

function getType(file: any) {
  const { type } = file;
  if (type.indexOf("svg") >= 0) {
    return "svg";
  }
  if (type.indexOf("image") >= 0) {
    return "image";
  }
  if (type.indexOf("video") >= 0) {
    return "video";
  }
  if (type.indexOf("audio") >= 0) {
    return "audio";
  }
  return "image";
}

export const VideoPanel = observer(
  ({ store, isNotPanel = true }: VideoPanelProps) => {
    const {
      ScriptDetails: [scriptDetails],
      UserDetail: [userDetails, setUserDetails],
      ActiveIndex: [activeIndex, setActiveIndex],
      ActiveElementId: [activeElementId, setActiveElementId],
      ActiveSearchQueries: [activeSearchQueries, setActiveSearchQueries],
      ActiveSidePanel: [, setActiveSidePanel],
    } = useGlobalContext();

    useEffect(() => {
      setActiveSidePanel(store.openedSidePanel);
    }, []);
    //   const index=activeIndex
    // console.log(elementID, index, isNotPanel, searchQueries)
    const [index, setIndex] = useState(activeIndex);
    const [elementID, setElementID] = useState(activeElementId);
    const [searchQueries, setSearchQueries] = useState(activeSearchQueries);

    const [searchQueryIndex, setSearchQueryIndex] = useState(0);
    const [imagePage, setImagePage] = useState(1);
    const [videoPage, setVideoPage] = useState(1);
    const [isUploading, setUploading] = useState(false);
    const [images, setImages] = useState<any>([]);
    const [videos, setVideos] = useState<any>([]);
    const [activeTabKey, setActiveTabKey] = useState("0");
    const [aiImageGenerating, setAiImageGenerating] = useState(false);
    const [type, setType] = useState("image");
    const [transformedVideos, setTransformedVideos] = useState<any[]>([]);
    const { uploadImage: uploadToServer } = useMultiPartServerS3();
    const [progress, setProgress] = useState<number | null>(null);
    const [
      transformedGettyImageVideos,
      setTransformedGettyImageVideos,
    ] = useState<any[]>([]);
    const [transformedGettyImages, setTransformedGettyImages] = useState<any[]>(
      []
    );

    const [selectedOrientationValue, setSelectedOrientationValue] = useState(
      userDetails?.purpose?.size === "9:16" ? "vertical" : "horizontal"
    );

    useEffect(() => {
      setSelectedOrientationValue(
        userDetails?.purpose?.size === "9:16" ? "vertical" : "horizontal"
      );
    }, [userDetails]);
    const [uploadingVideo, setUploadingVideo] = useState(false);

    // const { data:assetStatus, isLoading:isAsseStatusLoading, error:assetStatusError } = useGetAssetStatus(taskId || "",
    //   isPollingEnabled ? 5000 : undefined );

    
    const [transformedAIImage, setTransformedAIImage] = useState<
      { id: string; image_url: any; video_url: any; type: string }[]
    >([]);

    const handleTabChange = (key: any) => {
      setActiveTabKey(key);
    };

    const generateAiImage = async () => {
      setAiImageGenerating(true);
      try {
        const aiImage = await aiImagesAPI(
          debouncedSearchQuery,
          selectedOrientationValue,
          userDetails?.purpose?.aiImagePrefix || ""
        );
        const newImage = {
          id: generateUniqueId(),
          image_url: aiImage,
          video_url: aiImage,
          type: "image",
        };
        // transformedAIImage = [{id: generateUniqueId(), image_url:aiImage, video_url:aiImage, type:'image'}]
        // const addImage = (newImage: { id: string; image_url: any; video_url: any; type: string }) => {
        setTransformedAIImage((prevImages) => [...prevImages, newImage]);
        // };
      } catch (error) {
        console.error(error);
      } finally {
        setAiImageGenerating(false);
      }
    };
    function getExtension(filename: any) {
      return filename.split(".").pop();
    }
    const handleFileInput = async (e: any) => {
      const { target } = e;
      setUploading(true);
      for (const file of target.files) {
        // await uploadFile(file);
        const asset_name = file.name.split('.');
        await uploadToServer(file, asset_name[0], (progress) => {
      
        setProgress(progress)
        });
      }
      setProgress(null)
      refetchAroleAssets()

      setUploading(false);
      target.value = null;
    };

    const [searchQuery, setSearchQuery] = useState(
      scriptDetails?.data[store?.activePage?.custom?.scriptIndex]
        ?.searchQueries[index] || activeSearchQueries
        ? activeSearchQueries?.[0]
        : scriptDetails.data?.[0].searchQueries?.[searchQueryIndex] ||
            store?.activePage?.custom?.searchQuery ||
            ""
    );
    useEffect(() => {
      const ImageIndex =
        store?.activePage?.custom?.imageIndex <=
        scriptDetails?.data[store?.activePage?.custom?.scriptIndex]
          ?.searchQueries?.length -
          1
          ? store?.activePage?.custom?.imageIndex
          : 0;

      setSearchQuery(
        scriptDetails?.data[store?.activePage?.custom?.scriptIndex]
          ?.searchQueries[ImageIndex] || activeSearchQueries
          ? activeSearchQueries?.[0]
          : scriptDetails.data?.[0].searchQueries?.[searchQueryIndex] ||
              store?.activePage?.custom?.searchQuery ||
              ""
      );
    }, [store?.activePage]);
    useEffect(() => {
      const index =
        store?.activePage?.custom?.imageIndex <
        scriptDetails?.data[store?.activePage?.custom?.scriptIndex]
          ?.searchQueries?.length
          ? store?.activePage?.custom?.imageIndex
          : 0;
      setSearchQuery(
        scriptDetails?.data[store?.activePage?.custom?.scriptIndex]
          ?.searchQueries[index] || ""
      );
    }, [activeSearchQueries]);
    const debouncedSearchQuery = useDebounce<string>(searchQuery, 500); // Debounce search input to reduce API calls

    const { data: pexelsVideos, isLoading } = useGetPexelsVideos(
      PEXELS_KEY,
      debouncedSearchQuery, // Use the debounced query value
      "landscape",
      "medium"
    );

    const {
      data: imageData,
      isLoading: isImagesLoading,
      isError,
      isSuccess: isImagesSuccess,
      refetch: refetchImages,
    } = useGetAssets(imagePage, "image");

    const { data:aRoleAssets, isLoading:aRoleLoading, isFetching:aRoleFetching, fetchNextPage:aRolefetchNextPage, hasNextPage:aRoleHasNextPage, refetch:refetchAroleAssets} = useGetAroleAssets(type);
    useEffect(() => {
      if (aRoleAssets?.items) {
        const transformed = aRoleAssets.items.map((item: any) => ({
          id: item.id,
          image_url: item.media_url,
          video_url: item.media_url,
          type: item.type
        }));
        if (JSON.stringify(transformed) !== JSON.stringify(transformedVideos)) {
          setTransformedVideos(transformed);
        }
        // setTransformedVideos(transformed);
      }
    }, [aRoleAssets]);

    const handleImageLoadMore = () => {
      setImagePage(imagePage + 1);
    };

    const handleVideoLoadMore = () => {
      setVideoPage(videoPage + 1);
      aRolefetchNextPage()
    };



    const {
      data: gettyImageVideos,
      isLoading: isGettyImageVideoLoading,
      isSuccess: isGettyImageVideosSuccess,
      page: gettyVideoPage,
      setPage: setGettyVideoPage,
    } = useGetGettyImageVideos(
      debouncedSearchQuery,
      userDetails?.purpose?.size,
      selectedOrientationValue,
      15,
      1,
      userDetails?.purpose?.videotechniques || []
    );

    useEffect(() => {
      if (isGettyImageVideosSuccess && gettyImageVideos?.videos) {
        const newVideos = gettyImageVideos.videos.map((video: any) => {
          const videoPreview = video.display_sizes.find(
            (displaySize: any) => displaySize.name === "comp"
          );
          const imagePreview = video.display_sizes.find(
            (displaySize: any) => displaySize.name === "thumb"
          );

          return {
            id: video.id,
            image_url: imagePreview?.uri || "",
            video_url: videoPreview?.uri || "",
            type: "video",
          };
        });

        // Append new videos to existing state
        setTransformedGettyImageVideos((prevVideos) => [
          ...prevVideos,
          ...newVideos,
        ]);
      }
    }, [isGettyImageVideosSuccess, gettyImageVideos]);


    const {
      data: gettyImages,
      isLoading: isGettyImagesLoading,
      isSuccess: isGettyImagesSuccess,
      page: gettypageNumber,
      setPage: setGettyPageNumber,
    } = useGetGettyImages(
      debouncedSearchQuery,
      selectedOrientationValue,
      false,
      15,
      1
    );

    useEffect(() => {
      if (isGettyImagesSuccess && gettyImages) {
        // Transform new images
        const newTransformedImages = gettyImages.map((image: any) => {
          const videoPreview = image.display_sizes.find(
            (displaySize: { name: string }) => displaySize.name === "comp"
          );
          const imagePreview = image.display_sizes.find(
            (displaySize: { name: string }) => displaySize.name === "thumb"
          );

          return {
            id: image.id,
            image_url: imagePreview?.uri || "",
            video_url: videoPreview?.uri || "",
            type: "image",
          };
        });

        // Keep previous images and append new ones, avoiding duplicates
        setTransformedGettyImages((prevImages) => {
          const existingIds = new Set(prevImages.map((image) => image.id));
          const filteredNewImages = newTransformedImages.filter(
            (image: any) => !existingIds.has(image.id)
          );
          return [...prevImages, ...filteredNewImages]; // Append new images
        });
      }
    }, [gettyImages, isGettyImagesSuccess]); // Add dependencies

    const { data: videosData, refetch: refetchVideos } = useGetAssets(
      videoPage,
      "video"
    );

    useEffect(() => {
      if (imageData && imageData.items && imageData.items.length > 0) {
        setImages([...images, ...imageData.items]);
      }
    }, [imageData]);
    useEffect(() => {
      if (videosData && videosData.items && videosData.items.length > 0) {
        const imageVideoData: any[] = videosData.items.filter(
          (obj: any) => obj.type !== "audio"
        );

        setVideos([...videos, ...imageVideoData]);
      }
    }, [videosData]);


    const scrollRef = useRef<HTMLDivElement>(null);

    const handleBottomReached = (tabType: string) => {
      if (activeTabKey === "0") {
        setGettyVideoPage((prev) => prev + 1);
      } else if (activeTabKey === "1") {
        setGettyPageNumber((prev) => prev + 1);
        // handleGettyImageLoadMore()
      } else if (activeTabKey === "4") {
        aRolefetchNextPage()
      } 

    };
    const baseStyle: React.CSSProperties = {
      height: "90%",
      overflow: "auto",
      backgroundColor: "var(--color-primary-50)",
    };
    useEffect(() => {
      if (activeTabKey === "0") {
        setTransformedGettyImageVideos([]);
      } else if (activeTabKey === "1") {
        setTransformedGettyImages([]);
      }
    }, [selectedOrientationValue]);
    return (
      <div style={baseStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            position: "relative",
            bottom: "37px",
            right: "17px",
          }}
        >
          {isNotPanel === true && (
            <BlueprintButton
              style={{
                border: "none",
                boxShadow: "none",
              }}
              icon="undo"
              onClick={() => {
                if (searchQueryIndex === searchQueries.length - 1) {
                  setSearchQueryIndex(0);
                } else {
                  setSearchQueryIndex(searchQueryIndex + 1);
                }
                setSearchQuery(searchQueries[searchQueryIndex]);
              }}
            />
          )}
        </div>

        {activeTabKey == "3" ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search for AI Image"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: 16, marginRight: "4px" }}
                disabled={aiImageGenerating}
              />
              <Button
                onClick={() => generateAiImage()}
                disabled={aiImageGenerating}
                loading={aiImageGenerating}
                style={{
                  background: "transparent",
                  borderColor: "var(--color-primary-500)",
                  color: "var(--color-primary-500)",
                }}
              >
                Generate
              </Button>
            </div>
            <Collapse
              ghost
              style={{ padding: "0px", color: "var(--color-primary-500)" }}
            >
              <Panel
                header="Prefix for AI Image generation"
                key="1"
                style={{ padding: "0px", fontWeight: "bold" }}
              >
                <TextArea
                  placeholder="Enter prefix for AI image generation"
                  value={userDetails?.purpose?.aiImagePrefix}
                  onChange={(e) => {
                    setUserDetails((prevDetails: any) => ({
                      ...prevDetails, // spread the previous userDetails
                      purpose: {
                        ...prevDetails?.purpose, // spread the previous purpose object
                        aiImagePrefix: e.target.value, // update the aiImagePrefix property
                      },
                    }));
                  }}
                  style={{ marginBottom: 16, marginRight: "4px" }}
                  disabled={aiImageGenerating}
                />
              </Panel>
            </Collapse>
          </>
        ) : (
          <>
            <Input
              placeholder="Search for videos"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);

                if (activeTabKey === "0") {
                  setTransformedGettyImageVideos([]);
                } else if (activeTabKey === "1") {
                  setTransformedGettyImages([]);
                }
              }}
              style={{ marginBottom: 16 }}
            />
          </>
        )}

        <>
          <Tabs defaultActiveKey={"4"} onChange={handleTabChange}>
            {IS_FREE_PLATFORM === "true" && (
              <TabPane tab="Getty Images" key="0">
                <VideoSearch
                  transformedElementsArray={transformedGettyImages}
                  isLoading={isGettyImagesLoading}
                  store={store}
                  title={""}
                  elementID={activeElementId}
                  isNotPanel={isNotPanel}
                  isGettyImage={true}
                  dropDownValue={selectedOrientationValue}
                  setSelectedValue={setSelectedOrientationValue}
                  onBottomReached={handleBottomReached}
                />
              </TabPane>
            )}
            {IS_FREE_PLATFORM === "false" && (
              <>
                <TabPane tab="Getty Videos" key="0">
                  <VideoSearch
                    transformedElementsArray={transformedGettyImageVideos}
                    isLoading={isGettyImageVideoLoading}
                    store={store}
                    title={""}
                    elementID={activeElementId}
                    isGettyImage={true}
                    dropDownValue={selectedOrientationValue}
                    setSelectedValue={setSelectedOrientationValue}
                    isNotPanel={isNotPanel}
                    index={index}
                    ref={scrollRef}
                    onBottomReached={handleBottomReached}
                  />

                  {/* <div style={{display:'flex', justifyContent:'space-around', gap:'5px' }}>
                  <Button
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={handleGettyVideoPrev}
                  disabled={gettyVideoPage<2}
                  loading={isLoading}
                >
                  Prev
                </Button>
                <Button
                  style={{ width: '100%', marginTop: 20, backgroundColor:'--color-primary-200' }}
                  onClick={handleGettyVideoLoadMore}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Next
                </Button>
                </div> */}
                </TabPane>

                <TabPane tab="Getty Images" key="1">
                  <VideoSearch
                    transformedElementsArray={transformedGettyImages}
                    isLoading={isGettyImagesLoading}
                    store={store}
                    title={""}
                    elementID={activeElementId}
                    isNotPanel={isNotPanel}
                    isGettyImage={true}
                    dropDownValue={selectedOrientationValue}
                    setSelectedValue={setSelectedOrientationValue}
                    onBottomReached={handleBottomReached}
                  />

                  {/* <div style={{display:'flex', justifyContent:'space-around', gap:'5px' }}>
                  
                  <Button
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={handleGettyImagePrev}
                  disabled={gettypageNumber<2}
                  loading={isLoading}
                >
                  Prev
                </Button>
                <Button
                  style={{ width: '100%', marginTop: 20, backgroundColor:'--color-primary-200' }}
                  onClick={handleGettyImageLoadMore}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Next
                </Button>
                  </div> */}
                </TabPane>

                {/* <TabPane tab='Pexel' key='2'>
                <VideoSearch
                  transformedElementsArray={transformedPexelVideos}
                  isLoading={isLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane> */}
                <TabPane tab="AI Images" key="3">
                  <VideoSearch
                    transformedElementsArray={transformedAIImage}
                    isLoading={aiImageGenerating}
                    store={store}
                    title={""}
                    elementID={activeElementId}
                    isNotPanel={isNotPanel}
                    index={index}
                    dropDownValue={selectedOrientationValue}
                    setSelectedValue={setSelectedOrientationValue}
                    onBottomReached={handleBottomReached}
                  />
                </TabPane>
                {/* <TabPane tab='Storyblocks' key='3'>
                <VideoSearch
                  transformedElementsArray={transformedStoryBlocksVideos}
                  isLoading={isSbLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane>
              <TabPane tab='Pixaby' key='4'>
                <VideoSearch
                  transformedElementsArray={transformedPixabayVideos}
                  isLoading={isPixbyLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane> */}
              </>
            )}

            {/* {isNotPanel === true && IS_FREE_PLATFORM === 'false' && ( */}
            <TabPane tab="My Assets" key="4">
              <Button
                icon={<UploadOutlined />}
                style={{ width: "100%", paddingBottom: 20, marginBottom: 20 }}
                loading={isUploading || uploadingVideo}
                onClick={() => {
                  document.getElementById("input-file")?.click();
                }}
              >
               {progress ? `${progress}%` : 'Upload Asset'}
              </Button>

              <input
                type="file"
                id="input-file"
                style={{ display: "none" }}
                onChange={handleFileInput}
                accept="image/*, video/*, .mkv"
                multiple
              />
               <Select
                style={{
                  width: "50%",
                  border: "2px solid rgb(235, 233, 254)",
                  borderRadius: "5px",
                }}
                placeholder="Select Orientation"
                optionFilterProp="children"
                onChange={(value:any)=>setType(value)}
                value={type}
                dropdownRender={(menu) => <div>{menu}</div>}
              >
                {['image', 'video'].map((data, index) => (
                  <Select.Option key={index} value={data}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {data}
                    </div>
                  </Select.Option>
                ))}
              </Select>
              <VideoSearch
                transformedElementsArray={transformedVideos}
                isLoading={isImagesLoading}
                store={store}
                title={""}
                elementID={activeElementId}
                index={index}
                onBottomReached={handleBottomReached}
              />
              <Button
                style={{ width: "100%", marginTop: 20 }}
                onClick={handleVideoLoadMore}
                disabled={isLoading}
                loading={isLoading}
              >
                Load More
              </Button>
            </TabPane>
            {/* )} */}
          </Tabs>
        </>
      </div>
    );
  }
);

// define the new custom section
export const CustomVideoSearchTwo = {
  name: "video-editor",
  Tab: (props: any) => {
    return (
      <SectionTab
        name={IS_FREE_PLATFORM === "true" ? "Image Search" : "Media"}
        {...props}
      >
        <Video02Icon />
      </SectionTab>
    );
  },
  Panel: VideoPanel,
};

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    // Set debouncedValue to value after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup function to clear the timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}
