/* eslint-disable react/prop-types */
import { Button, Tab, Tabs } from '@blueprintjs/core'
import { useGetPolotnoFonts } from '../../../api/polotno/useGetPolotnoFonts'
import { observer } from 'mobx-react-lite'
import { registerNextDomDrop } from 'polotno/canvas/page'
import { StoreType } from 'polotno/model/store'
import { ImagesGrid } from 'polotno/side-panel/images-grid'
import { t } from 'polotno/utils/l10n'
import styled from 'polotno/utils/styled'
import React, { useEffect, useState } from 'react'
import { POLOTNO_KEY } from '../../../config'
import { TextOverlay } from './customPolotno/customTextOverlay'
import { Switch } from 'antd'
import { useGlobalContext } from '../../../context/globalContext'
import { SectionTab } from 'polotno/side-panel'
import { FontSizeOutlined } from '@ant-design/icons'
import { TextSmallcapsIcon } from './hugeIcons/hugeIcons'
const Container = styled('div')`
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;

  .bp5-dark & .polotno-text-preview-plain {
    filter: invert(1);
  }
`

const FontContainer = styled('div')`
  height: 100px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(16, 22, 26, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  color: white;
  margin-bottom: 10px;
`

const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

interface FontItemProps {
  onSelect: () => void // Assuming onSelect is a function with no parameters and no return value
  onRemove: () => void // Same assumption as onSelect
  font: {
    fontFamily: string // Assuming font object has a fontFamily property of type string
  }
}

const FontItem = observer(({ onSelect, onRemove, font }: FontItemProps) => {
  return (
    <FontContainer
      style={{
        fontFamily: font.fontFamily,
      }}
      className='polotno-font-item'
      onClick={onSelect}
    >
      {font.fontFamily} text
      <Button
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}
        minimal
        icon='trash'
        onClick={(e) => {
          e.stopPropagation()
          onRemove()
        }}
      />
    </FontContainer>
  )
})

interface DragButtonProps {
  onSelect: (position?: { x: number; y: number }) => void
  // Include other props here as necessary
}

const DragButton: React.FC<DragButtonProps> = ({ onSelect, ...props }) => {
  return (
    <Button
      {...props}
      draggable
      className='polotno-close-panel'
      onClick={() => onSelect()}
      onDragStart={() => {
        registerNextDomDrop(({ x, y }) => {
          onSelect({ x, y })
        })
      }}
      onDragEnd={(e) => {
        registerNextDomDrop(null)
      }}
    />
  )
}

export const TextPanel = observer(({ store }: { store: StoreType }) => {
  React.useEffect(() => {
    store.loadFont('Roboto')
  }, [])
  const {
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    ActiveSidePanel: [activeSidePanel, setActiveSidePanel],
  } = useGlobalContext()
  // const [showTextOverlay, setShowTextOverlay] = useState(true)
  useEffect(() => {
    setActiveSidePanel(store.openedSidePanel)
  }, [])
  const handleShowTextOverlay = () => {
    setShowTextOverlay(!showTextOverlay)
  }

  const addText = (attrs: { fontSize: any; text?: string; fontFamily?: string; width?: any; x?: any; y?: any }) => {
    const width = attrs.width || store.width / 2

    const x = (attrs?.x || store.width / 2) - width / 2
    const y = (attrs?.y || store.height / 2) - attrs.fontSize / 2

    const baseSize = 1080 + 1080
    const currentSize = store.width + store.height
    const scale = currentSize / baseSize

    const element = store.activePage?.addElement({
      type: 'text',
      fontFamily: 'Roboto',
      ...attrs,
      x,
      y,
      width: width,
      fontSize: attrs.fontSize * scale,
    })
    // if (!isMobile()) {
    //   element?.toggleEditMode(true)
    // }
  }
  const handleTextOverlayToggle = (e: any) => {
    setShowTextOverlay(e)

    store.pages.map((page: any) => {
      page.children.map((child: any) => {
        if (child.name === 'h1-notlinked') {
          const elementID = child.id
          const element = store.getElementById(elementID)
          if (element) {
            element.set({
              visible: e,
              opacity: e ? 1 : 0,
            })
          }
        }
      })
    })
    // }
  }
  const handleFileInput = async (e: { target: any }) => {
    const { target } = e
    for (const file of target.files) {
      const url = await toBase64(file)
      const name = file.name.split('.')[0]
      store.addFont({
        fontFamily: name,
      })
    }
    target.value = null
  }

  React.useEffect(() => {
    store.fonts.forEach((font) => store.loadFont(font.fontFamily))
  }, [store.fonts])

  // const { data, error } = useSWR(textTemplateList())
  const { data, isLoading: imageLoading } = useGetPolotnoFonts(`${POLOTNO_KEY}`)

  const [tab, selectTab] = React.useState('text-overlay')
  const baseStyle: React.CSSProperties = {
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'var(--color-primary-50)',
  }
  return (
    <div style={baseStyle}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Tabs large onChange={(tab) => selectTab(tab as string)}>
          <Tab id='text-overlay'>{'Text'}</Tab>
          <Tab id='text'>{'Custom Text'}</Tab>
          <Tab id='font'>{'Custom Fonts'}</Tab>
        </Tabs>
        {tab === 'text-overlay' && (
          <>
            <div className={'text-overlay-wrapper'}>
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3
                    style={{
                      color: 'black',
                      fontSize: '14px',
                      fontWeight: 500,
                      fontFamily: 'DM Sans',
                      letterSpacing: 'normal',
                      marginBottom: '5px',
                    }}
                  >
                    Display text on screen
                  </h3>
                  <Switch
                    defaultChecked={showTextOverlay}
                    value={showTextOverlay}
                    onChange={(e) => handleTextOverlayToggle(e)}
                    style={{ marginTop: '10px' }}
                  />
                </div>
                {store.pages.map((page: any, index: any) => {
                  return (
                    <div key={page.id}>
                      <h3 style={{ fontSize: '12px', fontWeight: 'normal' }}>Scene {index + 1}</h3>
                      <TextOverlay index={index} store={store} pages={page} />
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        )}
        {tab === 'text' && (
          <Container>
            <ImagesGrid
              shadowEnabled={false}
              images={data?.items}
              getPreview={(image) => image.preview}
              getImageClassName={(image: { preview: string; json: string }) => {
                const isPlain = image.json.indexOf('plain') >= 0
                return isPlain ? 'polotno-text-preview-plain' : ''
              }}
              isLoading={!data}
              error={Error}
              onSelect={async (item, pos) => {
                const req = await fetch(item.json)
                const json = await req.json()

                // what if all pages are removed while loading?
                // or no pages at all?
                if (!store.activePage) {
                  return
                }

                const baseSize = 1080 + 1080
                const currentSize = store.width + store.height
                const scale = currentSize / baseSize

                const dX = pos ? pos.x - (json.width / 2) * scale : store.width / 2 - (json.width / 2) * scale
                const dY = pos ? pos.y - (json.height / 2) * scale : store.height / 2 - (json.height / 2) * scale
                store.history.transaction(() => {
                  const texts = json.pages[0].children
                  const ids: any[] = []
                  texts.forEach(
                    (text: { id: any; fontSize: number; x: number; y: number; width: number; height: number }) => {
                      delete text.id
                      const { id } = store.activePage?.addElement({
                        ...text,
                        type: 'text',
                        fontSize: text.fontSize * scale,
                        x: text.x * scale + dX,
                        y: text.y * scale + dY,
                        width: text.width * scale,
                        height: text.height * scale,
                      }) as any
                      ids.push(id)
                    }
                  )
                  store.selectElements(ids)
                })
              }}
            />
          </Container>
        )}
        {tab === 'font' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100% - 50px)',
            }}
          >
            <label htmlFor='polotno-font-upload'>
              <Button
                icon='upload'
                style={{ width: '100%' }}
                onClick={() => {
                  ;(document.querySelector('#polotno-font-upload') as HTMLElement)?.click()
                }}
              >
                {t('sidePanel.uploadFont')}
              </Button>
              <input
                type='file'
                accept='.ttf, .otf, .woff, .woff2, .eot'
                id='polotno-font-upload'
                style={{ display: 'none' }}
                onChange={handleFileInput}
              />
            </label>
            <div style={{ paddingTop: '20px', overflow: 'auto', height: '100%' }}>
              {store.fonts.map((font, i) => (
                <FontItem
                  font={font}
                  key={i}
                  onSelect={() => {
                    store.loadFont(font.fontFamily)
                    store.pages.forEach((page) => {
                      page.children.forEach((element) => {
                        if (element.type === 'text') {
                          store.getElementById(element.id)?.set({ fontFamily: font.fontFamily })
                        }
                      })
                    })
                  }}
                  onRemove={() => {
                    store.removeFont(font.fontFamily)
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

export const CustomTextPanelPolotno = {
  name: 'text-panel',
  Tab: (props: any) => (
    <SectionTab name='Text' {...props}>
      <TextSmallcapsIcon className='sidebar-panel-tab-icon' />
    </SectionTab>
  ),
  Panel: TextPanel,
}
