import { Card, Input, Spin, Tooltip } from 'antd'
import { useEffect, useState, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useGlobalContext } from '@src/context/globalContext'
import VideoDurationSetting from '../../sceneSettings/videoTrimmerSetting'
import VideoTrimmer from '@src/features/polotno/videoTrimmer/videoTrimmer'
import { getVideoPreview } from 'polotno/utils/video'
import { Pages } from 'polotno/pages-timeline'
import { Message01Icon, ViewIcon, ViewOffSlashIcon } from '@src/features/polotno/component/hugeIcons/hugeIcons'
import Draggable from 'react-draggable'

// Add this type for better type safety
type Word = {
  word: string;
  start: number;
  end: number;
};

// Add this function to get words that fit within duration
const getWordsWithinDuration = (words: Word[], duration: number) => {
  return words.filter(word => word.end <= duration / 1000);
};

// Add this function to get trimmed words
const getTrimmedWords = (words: Word[], duration: number) => {
  return words.filter(word => word.end > duration / 1000);
};

const ScenesTab = ({ store }: any) => {
  const [jsonPages, setJsonpages] = useState<any>({})
  const [activePageId, setActivePageId] = useState('')
  const [captionWords, setCaptionWords] = useState([])
  const [textoverlay, setTextOverlay] = useState('')
  const [textOverlayId, setTextOverlayId] = useState('')
  const [videoSrc, setVideoSrc] = useState('')
  const [elementId, setElementId] = useState('')
  const [sceneElementType, setElementType] = useState('')
  const [newString, setNewString] = useState('')
  const [pageDuration, setPageDuration] = useState('')
  const [imagePreviews, setImagePreviews] = useState<string[]>([])
  const [editableWordIndex, setEditableWordIndex] = useState(null)
  const [editedWord, setEditedWord] = useState('')
  const [isDragging, setIsDragging] = useState(false)
  const [dragStartX, setDragStartX] = useState(0)
  const [lastDragX, setLastDragX] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)
  const [initialDuration, setInitialDuration] = useState(0)
  const [positions, setPositions] = useState<{ [key: string]: { x: number; y: number } }>({})
  const [sectionCaptions, setSectionCaptions] = useState<{[key: string]: Word[]}>({});

  const childs: any[] = []

  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    ActiveIndex: [activeIndex, setActiveIndex],
    ActiveElementId: [activeElementId, setActiveElementId],
    ActiveSearchQueries: [activeSearchQueries, setActiveSearchQueries],
  } = useGlobalContext()
  useEffect(() => {
    if (store?.activePage?.id) {
      setJsonpages(store.toJSON())
    }

    setActivePageId(store?.activePage?.id)
  }, [store, store.activePage])

  useEffect(() => {
    const storeJson = store.toJSON()
    if (storeJson.pages.length > 0 && scriptDetails && scriptDetails.data[scriptDetails.data.length - 1].captions) {
      //store.setScale(0.25);
      setActivePageId(store.activePage.id)
      store.pages.map((page: any, index: any) => {
        if (page.id === store.activePage.id) {
          setActiveIndex(store.activePage.custom.scriptIndex || index)
          setActiveSearchQueries(
            scriptDetails.data[store.activePage.custom.scriptIndex || index]?.searchQueries ||
              scriptDetails.data[store.activePage.custom.scriptIndex || index]?.images[0].searchQueries
          )
          setCaptionWords(scriptDetails.data[store.activePage.custom.scriptIndex || index]?.captions?.[0]?.words)
        }
      })

      setActiveElementId(store.activePage?.children[0].id)
      store.activePage?.children?.map((child: any, index: any) => {
        childs.push(child.type)
        if (child.name === 'h1-notlinked') {
          setTextOverlay(child.text)
          setTextOverlayId(child.id)
        }
        if (child.type == 'video') {
          setVideoSrc(child.src)
          setElementId(child.id)
          setElementType('video')
          setActiveElementId(child.id)
        }
      })
      if (!childs.includes('video')) {
        setVideoSrc('')
        setElementId('')
        setElementType('image')
      }
      setNewString(store.activePage?.custom?.captions?.[0]?.words.map((item: any) => item.word).join(' '))
    }
  }, [store.activePage, store, scriptDetails])
  useEffect(() => {
    store.setScale(0.3)
  }, [activePageId])
  const handleSceneClick = (pageId: string) => {
    store.selectPage(pageId)
  }

  useEffect(() => {
    const fetchImagePreviews = async () => {
      const previews = await Promise.all(
        jsonPages?.pages?.map(async (page: any) => {
          for (const child of page.children) {
            if (child.type === 'image') {
              // If child is an image, use its source and break the loop
              return child.src
            } else if (child.type === 'video') {
              // If child is a video, get the preview
              const imagePreview = await getVideoPreview(child.src || 'https://media.gettyimages.com/video/sample.mp4')
              return imagePreview
            }
          }
          // Return null if no matching child type is found
          return null
        })
      )

      // Filter out null values and set the previews
      const filteredPreviews = previews.filter((preview) => preview !== null)
      setImagePreviews(filteredPreviews)
    }

    if (jsonPages?.pages?.length > 0) fetchImagePreviews()
    store.setScale(0.3)
  }, [jsonPages, store, videoSrc])
  const colStyle: React.CSSProperties = {
    backgroundColor: 'var(--color-primary-100)',
    borderRadius: '10px',
    height: '100%', // Ensure the columns stretch to the row's height
  }

  const handleDrag = useCallback(
    (e: any, ui: any, pageId: string, initialDuration: number) => {
      const { x } = ui

      // Convert drag distance to duration (20px = 100ms)
      const durationChange = Math.round(x / 20) * 100
      const newDuration = Math.max(500, initialDuration + durationChange)

      // Only update the duration of the specific page being dragged
      // instead of always using activePage
      const pageToUpdate = store.pages.find((page: any) => page.id === pageId)
      if (pageToUpdate) {
        pageToUpdate.set({
          duration: newDuration,
        })
        
        if (pageId === store.activePage.id) {
          setPageDuration(newDuration.toString())
        }

        // Force immediate update
        setJsonpages(store.toJSON())
      }

      // Update position for this specific handle
      setPositions((prev) => ({
        ...prev,
        [pageId]: { x, y: 0 },
      }))
    },
    [store]
  )

  const handleDragStop = useCallback(
    (pageId: string) => {
      const finalX = positions[pageId]?.x || 0
      setPositions((prev) => ({
        ...prev,
        [pageId]: { x: finalX, y: 0 },
      }))

      // Update only duration on stop
      setJsonpages(store.toJSON())
    },
    [positions, store]
  )

  // Calculate total duration and timestamps whenever pages change
  useEffect(() => {
    if (jsonPages?.pages) {
      let total = 0
      const newTimestamps = jsonPages.pages.reduce((acc: any, page: any) => {
        acc[page.id] = {
          start: total / 1000,
          end: (total + page.duration) / 1000,
        }
        total += page.duration
        return acc
      }, {})
      setTotalDuration(total)
    }
  }, [jsonPages])

  const getSceneStartTime = (index: number) => {
    let startTime = 0
    for (let i = 0; i < index; i++) {
      startTime += jsonPages?.pages[i]?.duration || 0
    }
    return (startTime / 1000).toFixed(1)
  }

  const getSceneEndTime = (index: number) => {
    let endTime = 0
    for (let i = 0; i <= index; i++) {
      endTime += jsonPages?.pages[i]?.duration || 0
    }
    return (endTime / 1000).toFixed(1)
  }

  // Add this function to group pages by scriptIndex
  const groupPagesByScriptIndex = (pages: any[]) => {
    return pages.reduce((groups: any, page: any) => {
      const scriptIndex = page.custom?.scriptIndex || 0;
      if (!groups[scriptIndex]) {
        groups[scriptIndex] = [];
      }
      groups[scriptIndex].push(page);
      return groups;
    }, {});
  };

  // Add this useEffect to set captions for each section
  useEffect(() => {
    if (scriptDetails?.data) {
      const captionsMap: {[key: string]: Word[]} = {};
      scriptDetails.data.forEach((section: any, index: number) => {
        if (section.captions?.[0]?.words) {
          captionsMap[index] = section.captions[0].words;
        }
      });
      setSectionCaptions(captionsMap);
    }
  }, [scriptDetails]);

  // Add this function to calculate total duration for a script index
  const getScriptIndexDuration = (scriptIndex: number) => {
    if (!jsonPages?.pages) return 0;
    return jsonPages.pages
      .filter((page: any) => page.custom?.scriptIndex === scriptIndex)
      .reduce((total: number, page: any) => total + page.duration, 0);
  };

  // Add this function to calculate the start time of a section
  const getSectionStartTime = (scriptIndex: number) => {
    if (!jsonPages?.pages) return 0;
    
    let startTime = 0;
    for (let i = 0; i < scriptIndex; i++) {
      startTime += getScriptIndexDuration(i);
    }
    return startTime;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {/* Sections row */}
      <div
        style={{
          display: 'flex',
          overflowX: 'auto',
          gap: '24px',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}
      >
        {jsonPages?.pages && Object.entries(groupPagesByScriptIndex(jsonPages.pages)).map(([scriptIndex, pages]: [string, any]) => {
          const sectionWords = scriptDetails?.data[parseInt(scriptIndex)]?.captions?.[0]?.words || [];
          const totalSectionDuration = getScriptIndexDuration(parseInt(scriptIndex));
          const wordsWithinDuration = getWordsWithinDuration(sectionWords, totalSectionDuration);
          const trimmedWords = getTrimmedWords(sectionWords, totalSectionDuration);
          const sectionStartTime = getSectionStartTime(parseInt(scriptIndex));
          const pageDuration = pages.reduce((total: number, page: any) => total + page.duration, 0);
          return (
            <div 
              key={`section-${scriptIndex}`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                minWidth: 'fit-content',
                background: 'var(--color-primary-50)',
                padding: '12px',
                borderRadius: '8px',
                border: '1px solid var(--color-primary-200)',
              }}
            >
              <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}>
                <div style={{ 
                  padding: '4px 8px',
                  backgroundColor: 'var(--color-primary-100)',
                  borderRadius: '4px',
                  width: 'fit-content',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: 'var(--color-primary-700)',
                }}>
                  Section {parseInt(scriptIndex) + 1}  - {(totalSectionDuration / 1000).toFixed(1)}s
                </div>

                {/* Add total duration display if needed */}
                {/* <div style={{
                  position: 'absolute',
                  top: '-8px',
                  right: '8px',
                  backgroundColor: 'var(--color-primary-100)',
                  padding: '2px 8px',
                  borderRadius: '4px',
                  fontSize: '11px',
                  color: 'var(--color-primary-700)',
                }}>
                  {(totalSectionDuration / 1000).toFixed(1)}s
                </div> */}

                {/* Captions display */}
                <div style={{
                  fontSize: '12px',
                  padding: '8px',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  width: `${(pageDuration / 1000) * 50}px`,
                  minWidth: '100px',
                  maxHeight: '100px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}>
                  {wordsWithinDuration.map((word, idx) => (
                    <Tooltip 
                      key={idx}
                      title={`${((sectionStartTime + word.start * 1000) / 1000).toFixed(1)}s - ${((sectionStartTime + word.end * 1000) / 1000).toFixed(1)}s`}
                    >
                      <span style={{
                        display: 'inline-block',
                        padding: '2px 4px',
                        margin: '2px',
                        backgroundColor: 'var(--color-primary-50)',
                        borderRadius: '4px',
                      }}>
                        {word.word}
                      </span>
                    </Tooltip>
                  ))}
                  {trimmedWords.length > 0 && (
                    <div style={{
                      marginTop: '4px',
                      padding: '4px',
                      backgroundColor: '#FFEBEE',
                      borderRadius: '4px',
                      color: '#C62828',
                    }}>
                      Trimmed words: {trimmedWords.map(w => w.word).join(' ')}
                    </div>
                  )}
                </div>
              </div>
              
              <div style={{ 
                display: 'flex',
                gap: '8px',
                position: 'relative',
              }}>
                {pages.map((page: any, index: number) => {
                  const startTime = getSceneStartTime(jsonPages.pages.findIndex((p: any) => p.id === page.id));
                  const endTime = getSceneEndTime(jsonPages.pages.findIndex((p: any) => p.id === page.id));

                  return (
                    <div 
                      key={`${page.id}-${index}`} 
                      style={{ 
                        position: 'relative',
                        display: 'flex',
                        filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))',
                      }}
                    >
                      <Card
                        style={{
                          cursor: 'pointer',
                          flexShrink: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          border: `${activePageId === page.id ? '2px solid var(--color-primary-500)' : '1px solid var(--color-primary-200)'}`,
                          width: `${(page.duration / 1000) * 50}px`,
                          minWidth: '100px',
                          height: '100px',
                          padding: '0px',
                          overflow: 'hidden',
                          transition: 'all 0.3s ease',
                          position: 'relative',
                          marginRight: '10px',
                          borderRadius: '6px',
                          backgroundColor: activePageId === page.id ? 'var(--color-primary-50)' : 'white',
                        }}
                        onClick={() => handleSceneClick(page.id)}
                        hoverable
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                          }}
                        >
                          {imagePreviews?.[jsonPages.pages.findIndex((p: any) => p.id === page.id)] ? (
                            <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                              {[...Array(Math.ceil((page.duration / 1000) * 2))].map((_, i) => (
                                <img
                                  key={i}
                                  src={imagePreviews[jsonPages.pages.findIndex((p: any) => p.id === page.id)]}
                                  alt={`Scene ${index + 1}`}
                                  style={{
                                    height: '100%',
                                    width: `${50 * (page.duration / 1000)}px`,
                                    objectFit: 'contain',
                                    flexShrink: 0,
                                    backgroundColor: 'black',
                                    display: 'block',
                                  }}
                                />
                              ))}
                            </div>
                          ) : (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                              <Spin />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: 'rgba(0,0,0,0.7)',
                            color: 'white',
                            padding: '4px',
                            fontSize: '12px',
                            textAlign: 'center',
                            borderBottomLeftRadius: '6px',
                            borderBottomRightRadius: '6px',
                          }}
                        >
                          <div>{`${(page.duration / 1000).toFixed(1)}s`}</div>
                          <div>{`${startTime}s - ${endTime}s`}</div>
                        </div>
                      </Card>
                      <Draggable
                        axis='x'
                        position={{ x: 0, y: 0 }}
                        onDrag={(e, ui) => handleDrag(e, ui, page.id, page.duration)}
                        onStop={() => handleDragStop(page.id)}
                        bounds={{ left: -100, right: 1000 }}
                        positionOffset={{ x: (page.duration / 1000) * 50 - 5, y: 0 }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            height: '100%',
                            width: '10px',
                            cursor: 'ew-resize',
                            background: 'linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.3) 100%)',
                            zIndex: 1000,
                            borderRadius: '0 4px 4px 0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            style={{
                              width: '2px',
                              height: '20px',
                              background: 'rgba(255, 255, 255, 0.7)',
                              borderRadius: '1px',
                            }}
                          />
                        </div>
                      </Draggable>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {/* Video Trimmer */}
      {videoSrc && (
        <div style={{ width: '1000px', paddingTop: '15px' }}>
          <p style={{ color: 'var(--color-primary-500)', fontWeight: 'bold', marginLeft: '4px', marginBottom: '15px' }}>
            Video Trimmer
          </p>
          <VideoTrimmer videoUrl={videoSrc} store={store} pageId={activePageId} setPageDuration={setPageDuration} />
        </div>
      )}
    </div>
  )
}

export default observer(ScenesTab)
