import { getDomainID, qaBackendApiInstance } from '../config/config'
import { useInfiniteQuery } from 'react-query'
import { AxiosError } from 'axios'
// import { handleApiError } from '../utils/errorHandling'

// Fetch function
async function fetchAssets({ pageParam = 1, size, type, domain_id }: { 
  pageParam: number; 
  size: number; 
  type: string; 
  domain_id: string 
}) {
  if (!domain_id) {
    throw new Error('Domain ID is required.');
  }
  
  const endpoint = `/aRole/all?page=${pageParam}&size=${size}&domain_id=${domain_id}&assets_type=${type}`
  const response = await qaBackendApiInstance.get(endpoint);
  return response.data;
}

// Hook
export default function useGetAroleAssets(type: string, size = 10) {
  const domain_id = getDomainID()

  const { 
    data, 
    isLoading, 
    isFetching, 
    fetchNextPage, 
    hasNextPage,
    refetch 
  } = useInfiniteQuery(
    ['get-all-aRole-assets', domain_id, type],
    ({ pageParam }) => fetchAssets({ pageParam, size, type, domain_id }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage?.items || lastPage.items.length < size) return undefined;
        return allPages.length + 1;
      },
      enabled: !!domain_id,
      onError: (err: AxiosError) => {
        console.error('Error fetching aRole assets:', err);
      }
    }
  );

  // Combine all pages of data into a single array
  const assetsArray = data?.pages?.flatMap((page) => page.items) || [];
  const lastPageData = data?.pages?.[data.pages.length - 1] || {};

  return {
    data: {
      ...lastPageData,
      items: assetsArray
    },
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch
  };
}
