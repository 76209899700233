import { TransitionSeries, springTiming, linearTiming } from '@remotion/transitions'
import React from 'react'
import { AbsoluteFill, Audio, Sequence, interpolate, staticFile } from 'remotion'
import { burstImageTransitionFunctions, transitionAnimationsDropDown } from '../../../data/transitions'
import { generateUniqueId } from '../../../shared/utils/core'
import './fonts.css'
import { SinglePage } from './singlePage'
import { AnimatedSubtitles } from './subtitles'
import { useGlobalContext } from '../../../context/globalContext'
import { slideEffect } from '../../../assets'
import { addSound } from './addSound'
import RenderPosterComponent from './renderPoster'
import RenderPosterComponentTwo from './renderPosterComp'
import { loadFont } from '@remotion/fonts'
import { millisecondsToFrames } from '../../../shared/utils/remotion'
import * as Montserrat from '@remotion/google-fonts/Montserrat'
import * as Bangers from '@remotion/google-fonts/Bangers'
import * as ComicNeue from '@remotion/google-fonts/ComicNeue'
import * as Inter from '@remotion/google-fonts/Inter'
import * as Lato from '@remotion/google-fonts/Lato'
import * as Merriweather from '@remotion/google-fonts/Merriweather'
import * as Mulish from '@remotion/google-fonts/Mulish'
import * as OpenSans from '@remotion/google-fonts/OpenSans'
import * as Oswald from '@remotion/google-fonts/Oswald'
import * as Poppins from '@remotion/google-fonts/Poppins'
import * as Roboto from '@remotion/google-fonts/Roboto'
import * as SourceCodePro from '@remotion/google-fonts/SourceCodePro'
import * as SourceSans3 from '@remotion/google-fonts/SourceSans3'
import * as VarelaRound from '@remotion/google-fonts/VarelaRound'
import { removeVideoTags } from '../../../shared/utils/remotion/htmlProcessing'

// import { getAvailableFonts } from "@remotion/google-fonts";
// import { useGlobalContext } from '../../context/globalContext'

interface HtmlProp {
  htmlData: any
  jsonData: any
  scriptDetails: any
  width: number
  height: number
  transitionDurationInFrames: number
  transitionAnimationProp: any
  isDownload: boolean
  burstImagesTransitionAnimationArray: any
  videoStyle: string
  aiAvatar: boolean
}
// TO-DO -> Make this dynamic from user input - Hard coded FPS
const fps = 30
const delayInSeconds = 1

export const MyCompositionHtmlComponent: React.FC<HtmlProp> = ({
  htmlData,
  jsonData,
  scriptDetails,
  width,
  height,
  transitionDurationInFrames,
  transitionAnimationProp,
  isDownload,
  burstImagesTransitionAnimationArray,
  videoStyle,
  aiAvatar,
}) => {
  loadFont({
    family: 'Konga next',
    url: staticFile('fonts/Konga Next.otf'),
  }).then(() => console.log('Font loaded!'))
  loadFont({
    family: 'THE BOLD FONT',
    url: staticFile('fonts/THE BOLD FONT.otf'),
  }).then(() => console.log('Font loaded!'))
  // Load all fonts
  Montserrat.loadFont()
  Bangers.loadFont()
  ComicNeue.loadFont()
  Inter.loadFont()
  Lato.loadFont()
  Merriweather.loadFont()
  Mulish.loadFont()
  OpenSans.loadFont()
  Oswald.loadFont()
  Poppins.loadFont()
  Roboto.loadFont()
  SourceCodePro.loadFont()
  SourceSans3.loadFont()
  VarelaRound.loadFont()
  const audioFade = scriptDetails?.audioFade || false
  const transitionFunction: any =
    transitionAnimationsDropDown.find((item) => item.value === transitionAnimationProp)?.functions ||
    transitionAnimationsDropDown[0].functions

  const customTransitionEffect =
    scriptDetails?.customTransitionSoundurl ||
    'https://d2tqfxp1334laf.cloudfront.net/image_projects/da764da2-2982-49be-88bd-eca414c4f5d7/assets/audio/e4cdb276-d1ad-4d81-91ae-3182d55c4099/audio.mp3'
  const transitionSoundEffect = scriptDetails?.customtransitionSound
    ? customTransitionEffect
    : `audio/transitions_/${transitionAnimationProp}.mp3`

  /********* Get Caption styles and check if we need to show them or not *********/
  const captionsTextStyle = scriptDetails?.template
  const showSubtitles = scriptDetails?.showSubtitles

  const newCaptionStyle = {
    ...captionsTextStyle,
    position: captionsTextStyle?.position - 100,
  }
  // const verticalCaptionStyle = {
  //   ...captionsTextStyle,
  //   position: captionsTextStyle?.position + 100,
  // };
  const verticalCaptionStyle = {
    ...captionsTextStyle,
    position: captionsTextStyle?.position,
  }
  // const newcaptionStyle=captionsTextStyle
  // newcaptionStyle['position']=208
  /********* Get Background music URL and volume and voiceover volume *********/

  const backgroundMusicUrl = scriptDetails?.backgroundMusic?.url.startsWith('audio/')
    ? staticFile(scriptDetails?.backgroundMusic?.url)
    : scriptDetails?.backgroundMusic?.url
  const proxyBackgroundUrl =
    'https://d2tqfxp1334laf.cloudfront.net/image_projects/195c0a0b-5507-4fdb-9d94-521270353a66/assets/audio/251687ab-4155-4ee3-86a6-dc5bc70fd0b2/audio.mp3'
  const backgroundMusicVolume = scriptDetails?.backgroundMusic?.backgroundVolume || 1
  const backgroundmusicDurationInMili = scriptDetails?.backgroundMusic?.duration * 1000
  const backgroundmusicInframes = millisecondsToFrames(backgroundmusicDurationInMili, 30)
  // const voiceOverVolume = scriptDetails?.aiVoiceVolume
  //   ? scriptDetails?.aiVoiceVolume
  //   : 0.7;
  const voiceOverVolume = scriptDetails?.aiVoiceVolume ?? 0.7

  // const backgroundSound = scriptDetails?.backgroundSound || true
  const backgroundSound = scriptDetails?.backgroundSound ?? true
  const transitionSound = scriptDetails?.transitionSound ?? false
  const aiVoiceSpeed = scriptDetails?.aiVoiceSpeed || 1
  /********* Store last screen index for transition check *********/
  const lastScreen = scriptDetails?.data.length - 1
  const overlayAnimation = scriptDetails?.overlayAnimation || false
  let accumulatedFrames = 0

  let pageIndex = 0

  const accumulatedFramesArray: any = []

  const isThumbnailVisible = scriptDetails?.thumbnailTextVisible ?? true

  return (
    <>
      <TransitionSeries>
        {(scriptDetails?.showThumbnail ?? true) && (
          <TransitionSeries.Sequence key={`thumbnail-sequence`} durationInFrames={1}>
            <RenderPosterComponentTwo
              height={height}
              width={width}
              thumbnailImage={scriptDetails?.thumbnailImage}
              thumbnailMessage={
                scriptDetails?.thumbnailText || scriptDetails?.data[0].textOverlay || scriptDetails?.data[1].textOverlay
              }
              isThumbnailVisible={isThumbnailVisible}
            />
          </TransitionSeries.Sequence>
        )}

        {scriptDetails?.data.map((page: any, index: number) => {
          /********* Get the page JSON and HTML if Simple video -> 1 page === 1 JSON page. Indexes are ordered between both objects *********/
          const jsonPage = jsonData.pages[index]
          const pageHtml = htmlData[index]

          /********* Get the JSON and HTML of all pages that are linked to this page object. 1 page object ===  page.images.length No of pages *********/
          const jsonPages: any = []
          const htmlPages: any = []
          let durationInFrames: any = 0

          if (videoStyle === 'Burst') {
            /********* Loop over pages and add them to an array of JSON and HTML pages array until the loop matches pageIndex + page.images.length *********/
            for (let i = pageIndex; i < pageIndex + page.images.length; i++) {
              jsonPages.push(jsonData.pages[i])
              htmlPages.push(htmlData[i])

              if (jsonData.pages[i]?.duration) {
                durationInFrames += (jsonData.pages[i]?.duration / 1000) * fps
              }
            }
            pageIndex += page.images.length
          } else if (videoStyle === 'Simple') {
            jsonPages.push(jsonData.pages[pageIndex])
            htmlPages.push(htmlData[pageIndex])
            if (jsonData.pages[pageIndex]?.duration) {
              durationInFrames += (jsonData.pages[pageIndex].duration / 1000) * fps
            }

            pageIndex++
          }

          const sequenceKey = generateUniqueId()
          const transitionKey = generateUniqueId()

          const captions = page?.captions ? page?.captions[0]?.words : undefined

          const voiceOver = page?.sounds ? page?.sounds[0]?.url : undefined
          const voiceOverDuration = page?.sounds ? page?.sounds[0]?.audioDuration : undefined

          const audioDelay = fps * delayInSeconds

          const voiceOverDurationFrame = Math.round((voiceOverDuration + delayInSeconds) * fps)

          // Page start and end frames
          accumulatedFramesArray.push(accumulatedFrames)
          const pageStartFrame = accumulatedFrames
          const pageEndFrame = accumulatedFrames + durationInFrames + transitionDurationInFrames / 10
          accumulatedFrames += durationInFrames
          if (pageEndFrame >= backgroundmusicInframes) {
            accumulatedFrames = 0
          }
          let volume = backgroundMusicVolume ? backgroundMusicVolume : 0.5

          const transitionBusrtAnimations = burstImagesTransitionAnimationArray[index]
          const remainingDuration = durationInFrames
          const adjustedTransitionDuration = transitionDurationInFrames
          // const adjustedTransitionDuration = Math.min(
          //   transitionDurationInFrames,
          //   remainingDuration
          // );

          // const hasMuxStream = jsonPage?.children?.some((child: any) => child?.src?.includes('stream.mux'));
          if (durationInFrames == 0) {
            durationInFrames = 1
          }
          return (
            <>
              <TransitionSeries.Sequence
                key={`sequence-${index}-${sequenceKey}`}
                durationInFrames={durationInFrames + adjustedTransitionDuration}
              >
                {/* Add background Audio in Transition so we can add sound fading using interpolation */}
                {backgroundSound && (
                  <Audio
                    // loop
                    src={backgroundMusicUrl || proxyBackgroundUrl}
                    startFrom={pageStartFrame}
                    endAt={pageEndFrame}
                    // startFrom={0}
                    // endAt={videoEndDurationInFrames}
                    volume={(f) => {
                      if (audioFade) {
                        if (f <= audioDelay) {
                          volume = interpolate(
                            f,
                            [0, audioDelay],
                            [backgroundMusicVolume, backgroundMusicVolume / 10],
                            {
                              extrapolateLeft: 'clamp',
                              extrapolateRight: 'clamp',
                            }
                          )
                        } else if (f >= voiceOverDurationFrame) {
                          if (index === lastScreen) {
                            volume = interpolate(
                              f,
                              [voiceOverDurationFrame, durationInFrames + transitionDurationInFrames],
                              [volume, volume / 10],
                              {
                                extrapolateLeft: 'clamp',
                                extrapolateRight: 'clamp',
                              }
                            )
                          } else {
                            volume = interpolate(
                              f,
                              [voiceOverDurationFrame, durationInFrames + transitionDurationInFrames],
                              [volume, backgroundMusicVolume],
                              {
                                extrapolateLeft: 'clamp',
                                extrapolateRight: 'clamp',
                              }
                            )
                          }
                        } else if (f >= audioDelay && f <= voiceOverDurationFrame) {
                          volume = backgroundMusicVolume / 10
                        }
                        return volume
                      }

                      return volume
                    }}
                  />
                )}

                {videoStyle === 'Burst' ? (
                  // If the videoStyle is Burst then render the Burst video where multiple images are played on one scene / Ad block type / Script Page
                  // This is a sequence of images with a transition between each image / jsonPage
                  <TransitionSeries>
                    {jsonPages?.map((jsonPage: any, index: number) => {
                      // Get the transition animation function for the burst images
                      const transitionAnimationFunction: any =
                        burstImageTransitionFunctions.find((item) => item.value === transitionBusrtAnimations?.[index])
                          ?.function || burstImageTransitionFunctions[0].function
                      //  Calculate the number of frames for each page / image
                      let pageFrames = 0
                      if (jsonPage?.duration) {
                        pageFrames = (jsonPage?.duration / 1000) * fps
                      } else {
                        pageFrames = 0
                      }

                      function removeElementsByIds(html: string, idsToRemove: any) {
                        // Parse the HTML string into a DOM object
                        const parser = new DOMParser()
                        const doc = parser.parseFromString(html, 'text/html')

                        // Loop through the array of IDs and remove each corresponding element
                        idsToRemove.forEach((id: any) => {
                          const elementToRemove = doc.getElementById(id)
                          if (elementToRemove) {
                            elementToRemove.remove()
                          }
                        })

                        // Return the updated HTML as a string
                        return doc.body.innerHTML
                      }
                      let aiAvatar = false
                      // const pageHtml=htmlPages[index]
                      const imageids: any = []

                      if (
                        page?.aiAvatarProps?.avatarWithBg ||
                        page?.aiAvatarProps?.avatar ||
                        page?.aiAvatarProps?.avatarWithWebsite
                      )
                        jsonPage?.children.map((child: any, index: number) => {
                          if (child.type == 'image') {
                            imageids.push(child.id)
                            aiAvatar = true
                          } else if (child.type == 'video') {
                            if (child?.custom?.aiAvatar) {
                              aiAvatar = child?.custom?.aiAvatar || false
                              return
                            }
                          }
                        })

                      // const pageHtml=removeElementsByIds(htmlPages[index],imageids)
                      let pageHtml = removeVideoTags(htmlPages[index])
                      if (aiAvatar) {
                        pageHtml = removeElementsByIds(pageHtml, imageids)
                      }
                      return (
                        <>
                          <TransitionSeries.Sequence
                            key={`transitionSeriesSequence-${index}`}
                            durationInFrames={pageFrames + 20}
                          >
                            {jsonPage?.children && (
                              <SinglePage
                                key={index}
                                pageHtml={pageHtml}
                                width={width}
                                height={height}
                                pageData={jsonPage}
                                isDownload={isDownload}
                                aiAvatar={
                                  page?.aiAvatarProps?.avatarWithBg ||
                                  page?.aiAvatarProps?.avatar ||
                                  page?.aiAvatarProps?.avatarWithWebsite
                                }
                                overlayAnimation={overlayAnimation}
                                webScroll={page?.aiAvatarProps?.avatarWithWebsite}
                              />
                            )}
                            {!jsonPage?.children && (
                              <AbsoluteFill>
                                <div>{''}</div>
                              </AbsoluteFill>
                            )}
                            {/* <Audio
                                src={staticFile('audio/slideEffect.mp3')}
                                volume={0.02}
                               
                              /> */}
                          </TransitionSeries.Sequence>
                          <TransitionSeries.Transition
                            key={`transitionSeriesTransition-${index}`}
                            presentation={transitionAnimationFunction}
                            // timing={linearTiming({ durationInFrames: 30 })}
                            timing={springTiming({
                              config: { damping: 200 },
                              durationInFrames: 30,
                              durationRestThreshold: 0.001,
                            })}
                          />
                        </>
                      )
                    })}
                  </TransitionSeries>
                ) : (
                  // If the videoStyle is not Burst then render the Simple video where one Audio is played on one scene
                  <SinglePage
                    pageHtml={pageHtml}
                    width={width}
                    height={height}
                    pageData={jsonPage}
                    isDownload={isDownload}
                    aiAvatar={
                      page?.aiAvatarProps?.avatarWithBg ||
                      page?.aiAvatarProps?.avatar ||
                      page?.aiAvatarProps?.avatarWithWebsite
                    }
                    overlayAnimation={overlayAnimation}
                    webScroll={page?.aiAvatarProps?.avatarWithWebsite}
                  />
                )}
                {/* Add a sequence series for AI Voice audio and subtitles */}
                <Sequence
                  from={0.5 * fps} // TO-DO -> Make this dynamic (editable by the user) - Delay for the AI Voice and Subtitles is hardcoded in the sequence so that the audio and subtitles start 0.5 seconds after the video
                  durationInFrames={durationInFrames + transitionDurationInFrames} // The duration of the AI Voice and Subtitles is the same as the video
                >
                  {/* // If AI Voice audio is available, add it to the sequence */}

                  {/* {(voiceOver) && (
                    <Audio
                      src={voiceOver}
                      startFrom={0}
                      volume={voiceOverVolume}
                      playbackRate={aiVoiceSpeed}
                    />
                  )} */}

                  {!isDownload ? (
                    <>
                      {voiceOver && (
                        <Audio
                          src={voiceOver}
                          startFrom={0}
                          volume={voiceOverVolume}
                          playbackRate={aiVoiceSpeed}
                          // pauseWhenBuffering={true}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {!(
                        page?.aiAvatarProps?.avatarWithBg ||
                        page?.aiAvatarProps?.avatar ||
                        page?.aiAvatarProps?.avatarWithWebsite
                      ) && (
                        <>
                          {voiceOver && (
                            <Audio
                              src={voiceOver}
                              startFrom={0}
                              volume={voiceOverVolume}
                              playbackRate={aiVoiceSpeed}
                              // pauseWhenBuffering={true}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {/* // If Captions are enabled and available, add them to the sequence */}

                  {captions && captionsTextStyle && showSubtitles && (
                    <AnimatedSubtitles
                      captions={captions}
                      width={width}
                      height={height}
                      isDownload={isDownload}
                      style={width < height ? verticalCaptionStyle : newCaptionStyle}
                      // style={newCaptionStyle}
                    />
                  )}
                </Sequence>
              </TransitionSeries.Sequence>

              {/*
               Don't Add the transition series for the last scene as it will show a blank white screen in the end otherwise
               Add a transition series for the transition between scenes / Ad Block sections pages
               */}

              {index < scriptDetails?.data.length - 1 &&
                (transitionSound ? (
                  <TransitionSeries.Transition
                    key={`sequence-${index}-${transitionKey}`}
                    presentation={addSound(
                      transitionFunction,
                      scriptDetails?.customtransitionSound ? transitionSoundEffect : staticFile(transitionSoundEffect)
                    )} // Adding transition sound along with the effect
                    // timing={linearTiming({ durationInFrames: 30 })}
                    timing={springTiming({
                      config: { damping: 30, stiffness: 100 },
                      durationInFrames: adjustedTransitionDuration, // The duration of the transition between scenes / Ad Block sections pages - This is passed from the transition panel in right sidebar
                      durationRestThreshold: 0.005,
                    })}
                  />
                ) : (
                  <TransitionSeries.Transition
                    key={`sequence-${index}-${transitionKey}`}
                    presentation={transitionFunction}
                    // timing={linearTiming({ durationInFrames: 30 })}
                    timing={springTiming({
                      config: { damping: 200 },
                      durationInFrames: adjustedTransitionDuration, // The duration of the transition between scenes / Ad Block sections pages - This is passed from the transition panel in right sidebar
                      durationRestThreshold: 0.001,
                    })}
                  />
                ))}
            </>
          )
        })}
      </TransitionSeries>
    </>
  )
}

export const MyCompositionHtml = React.memo(MyCompositionHtmlComponent)
