function extractIdFromUrl(url: string): string | null {
  try {
    const idMatch = url.match(/\/id\/(\d+)\//);
    if (idMatch && idMatch[1]) {
      return idMatch[1];
    }
    return null;
  } catch (error) {
    return null;
  }
  }

  export default extractIdFromUrl