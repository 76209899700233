import { qaBackendApiInstance } from "@src/config/config"

export async function getHdAssets(assets:any){
    const token = localStorage.getItem('accessToken')
    
    const postBody={
      data:assets,
    //   domian_id:
    }
    try{
      const response = await qaBackendApiInstance.post('/video/gen-premium-assets',
        postBody,
        {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      }
  
      )
      return response.data
    }catch(error){
      return -1
    }
  }