import { useState } from 'react';
import { 
  CreateMultipartUploadCommand, 
  UploadPartCommand, 
  CompleteMultipartUploadCommand, 
  AbortMultipartUploadCommand, 
  S3Client,
  CompleteMultipartUploadCommandOutput
} from '@aws-sdk/client-s3';
// import { useQueryClient } from '@tanstack/react-query';

// import { API_BASE_URL } from '@/constants/api';
// import { getProtectedAxios } from '@/lib/auth';
import { getDomainID, qaBackendApiInstance } from '@src/config/config';
import { NODE_ENV } from '@src/config';
import { useQueryClient } from 'react-query';

interface UploadResponse {
  key: string;
  url: string;
}

interface KeyImagesResponse {
  key: string;
  url: string;
}

const isProd = NODE_ENV !== 'dev';
const S3_BUCKET = isProd ? 'quickads-project' : 'dev-quickads-project';
const REGION = 'ap-south-1';

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_PUBLIC_AWS_ACCESSKEYID as string,
    secretAccessKey: process.env.REACT_APP_PUBLIC_AWS_SECRETACCESSKEY as string
  }
});

const PART_SIZE = 5 * 1024 * 1024; // 5MB

async function keyImagesAPI(
  file: File | null, 
  asset_name: string, 
  domain_id: string, 
  isPublish?: boolean
): Promise<KeyImagesResponse | undefined> {
  if (!file) return undefined;

  const file_extension = file.name.split('.').pop();
  if (!file_extension) {
    throw new Error('File extension is missing.');
  }

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm'];
  
  let asset_type: 'image' | 'video';
  if (imageExtensions.includes(file_extension)) {
    asset_type = 'image';
  } else if (videoExtensions.includes(file_extension)) {
    asset_type = 'video';
  } else {
    throw new Error('Unsupported file type');
  }

  try {
    const response = await qaBackendApiInstance.post<KeyImagesResponse>('/aRole/get-arole-video-url', {
      asset_type,
      asset_name,
      domain_id,
      file_extension,
      isPublish: isPublish ?? false
    });
    return response.data;
  } catch (error) {
    console.error('Error generating upload key:', error);
    return undefined;
  }
}

export function useMultiPartServerS3() {
  const [data, setData] = useState<CompleteMultipartUploadCommandOutput | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const handleMultipartUpload = async (
    file: File, 
    fileKey: string, 
    progressCallback: (progress: number) => void
  ): Promise<CompleteMultipartUploadCommandOutput | null> => {
    let uploadId: string | undefined;
    const uploadedParts: { ETag: string; PartNumber: number }[] = [];

    try {
      // Step 1: Initiate the Multipart Upload
      const createResponse = await s3Client.send(
        new CreateMultipartUploadCommand({
          Bucket: S3_BUCKET,
          Key: fileKey,
          ContentType: file.type
        })
      );
      uploadId = createResponse.UploadId;

      // Step 2: Upload Parts
      const totalParts = Math.ceil(file.size / PART_SIZE);
      for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        const start = (partNumber - 1) * PART_SIZE;
        const end = Math.min(start + PART_SIZE, file.size);
        const filePart = file.slice(start, end);

        const uploadResponse = await s3Client.send(
          new UploadPartCommand({
            Bucket: S3_BUCKET,
            Key: fileKey,
            UploadId: uploadId,
            PartNumber: partNumber,
            Body: filePart
          })
        );

        if (uploadResponse.ETag) {
          uploadedParts.push({
            ETag: uploadResponse.ETag,
            PartNumber: partNumber
          });
        }

        const progress = Math.round((partNumber / totalParts) * 100);
        progressCallback(progress);
      }

      // Step 3: Complete the Multipart Upload
      const completeResponse = await s3Client.send(
        new CompleteMultipartUploadCommand({
          Bucket: S3_BUCKET,
          Key: fileKey,
          UploadId: uploadId,
          MultipartUpload: { Parts: uploadedParts }
        })
      );

      await queryClient.invalidateQueries(['get-all-aRole-assets']);
      return completeResponse;
    } catch (err) {
      console.error('Multipart upload failed:', err);

      // Abort the multipart upload in case of failure
      if (uploadId) {
        await s3Client.send(
          new AbortMultipartUploadCommand({
            Bucket: S3_BUCKET,
            Key: fileKey,
            UploadId: uploadId
          })
        );
      }

      throw new Error('Error uploading file');
    }
  };

  const uploadImage = async (
    file: File | null, 
    asset_name: string, 
    progressCallback: (progress: number) => void, 
    isPublish?: boolean
  ): Promise<CompleteMultipartUploadCommandOutput | null> => {
    setLoading(true);
    setError(null);
    
    try {
      const domain_id = getDomainID();
      const generatedData = await keyImagesAPI(file, asset_name, domain_id, isPublish);
      
      if (!generatedData?.key || !file) {
        throw new Error('Failed to generate upload key');
      }

      const result = await handleMultipartUpload(file, generatedData.key, progressCallback);
      setData(result);
      return result;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setError(errorMessage);
      console.error('Upload error:', err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { uploadImage, loading, data, error };
}
