import { observer } from 'mobx-react-lite'
import { Button, Tooltip } from '@blueprintjs/core'
import { useGlobalContext } from '../../../../context/globalContext'
import { handleCopyPage, handleDeletePage } from '../../../../shared/utils/polotno/pageOperations'
import { t } from 'polotno/utils/l10n'

export const PageControls = observer(({ store, page, xPadding, yPadding }: any) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    UndoStack: [undoStack, setUndoStack],
    RedoStack: [redoStack, setRedoStack],
    VideoStyle: [videoStyle],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
  } = useGlobalContext()

  const hasManyPages = store.pages.length > 1

  /* This will help us to get the index of each page, that we can then use in our delete and copy funtion. 
  Scrolling down in polotno will auto update the index value*/

  const index = store.pages.indexOf(page)

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: yPadding - 40 + 'px',
          right: xPadding + 'px',
        }}
      >
        <Tooltip content={t('workspace.duplicatePage')}>
          <Button
            icon='duplicate'
            minimal
            onClick={() => {
              handleCopyPage(
                store,
                index,
                scriptDetails,
                setScriptDetails,
                setUndoStack,
                setRedoStack,
                videoStyle,
                isStoreUpdated,
                setIsStoreUpdated
              )
            }}
          ></Button>
        </Tooltip>
        {hasManyPages && (
          <Tooltip content={t('workspace.removePage')}>
            <Button
              icon='trash'
              minimal
              onClick={() => {
                handleDeletePage(
                  store,
                  index,
                  scriptDetails,
                  setScriptDetails,
                  setUndoStack,
                  videoStyle,
                  isStoreUpdated,
                  setIsStoreUpdated,
                  false
                )
              }}
            ></Button>
          </Tooltip>
        )}
      </div>
    </>
  )
})
